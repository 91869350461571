<template>
    <section class="login-page-holder">

        <img class="st-patrick-day__login" src="/c2png/assets/themes/st-patrick/login-title.svg" alt="St Patricks Day with Shamrock" style="display:none;">

        <template v-if="checkCookieEnabled()">
            <component
                :is="activeForm"
                :urls="urls"
                :params="params"
            ></component>
        </template>
        <template v-else>
            <div class="login-form">
                <div class="login-form-logo"></div>
                <div class="login-form-failed-msg">
                    It appears that you do not have cookies enabled. C2P requires cookies to be enabled.
                </div>
            </div>
        </template>
    </section>
</template>

<script>
    import FormLogin from './components/FormLogin.vue';
    import FormForgot from './components/FormForgot.vue';
    import FormEula from "./components/FormEula";
    import FormSetupAcc from "./components/FormSetupAcc";
    import VFocus from "../../shared/DirectiveFocus"
    import {shouldShowStPatrickTheme} from "../../helpers";

    export default {
        props: {
            urls: {
                type: Object,
                required: true
            },
            params: {
                type: [String, Object],
                default: ''
            }
        },
        data() {
            return {
                activeForm: 'form-login',
                components: {
                    login: 'form-login',
                    forgot: 'form-forgot',
                    question: 'form-forgot',
                    resetPassword: 'form-forgot',
                    terms: 'form-terms',
                    accSetup: 'form-setup-acc',
                    questionAnswer: 'form-setup-acc',
                    viewEula: 'form-setup-acc',
                }
            }
        },
        created() {
            if (this.checkCookieEnabled() && !this.params.form) {
                sessionStorage.clear();
            }

            if (this.params.form) {
                if (this.params.passwordChangeRequired) {
                    this.activeForm = this.components.accSetup;
                } else {
                    this.activeForm = this.components[this.params.form];
                }
            }
            this.stPatrickDayStyling();
        },
        directives: {
            VFocus
        },
        methods: {
            stPatrickDayStyling() {
                if (shouldShowStPatrickTheme()) {
                    document.body.classList.add('st-patrick-day')
                }
            },
            checkCookieEnabled() {
                let GetsCookie = (navigator.cookieEnabled);
                if (typeof navigator.cookieEnabled === "undefined") {
                    document.cookie = "SampleCookie";
                    GetsCookie = (document.cookie.indexOf("SampleCookie") !== -1);
                }
                return GetsCookie;
            }
        },
        components: {FormSetupAcc, FormEula, FormLogin, FormForgot}
    }
</script>