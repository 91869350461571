<template>
    <svg class="svg-icon" :class="'icon--c-'+color" viewBox="0 0 16 16" :width="width" height="100%" preserverAspectRatio="xMinYMid">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 11V6V3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3V13C15 13.5523 14.5523 14 14 14H2C1.44772 14 1 13.5523 1 13V11ZM13 4.00003L8.34886 8.91272C8.16316 9.06692 7.89382 9.06644 7.70867 8.9116L3 4.00003V12H13V4.00003ZM8 7L5 4H11L8 7Z" />
    </svg>
</template>

<script>
import iconDefault from "./mixins/_iconDefault.js";

export default {
    mixins: [iconDefault],
    name: 'icon-email-envelope'
}
</script>