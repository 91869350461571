<template>
    <form class="login-form" :ref="'form'" :action="postUrl" method="post">
        <div class="login-form-logo"></div>

        <div class="login-form-failed-msg" v-if="showErrorLogin">
            {{getErrorMessage}}
        </div>
        <div v-else class="login-form-title">
            <div>{{getFormTitle}}</div>

            <ul class="login-form-title-list">
                <li v-if="setupAccStep === 1">Create new password</li>
                <li v-if="setupAccStep === 2">Create a security question</li>
                <li v-if="setupAccStep === 3">Accept license agreement</li>
                <li v-if="setupAccStep === 4">Please enter a new password</li>
            </ul>
        </div>

        <template v-if="setupAccStep === 1 || setupAccStep === 4">
            <!-- Step 1: Create new password -->
            <div class="form-group with-icon" :class="{'is-invalid': showErrorNewPassword}">
                <div class="form-group-password-popover" v-if="showPasswordPopover">
                    <div class="form-group-password-popover-inner">
                        <div class="form-group-password-popover-title">Password must</div>
                        <div class="form-group-password-popover-item"><i :class="[checkForNoOfCharacters ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least 8 characters</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForCharacters ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least one letter</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForNumbers ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least one number</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForRepeatingCharacters ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Cannot contain three repeating characters</span></div>
                        <div class="form-group-password-popover-note">
                            <div class="form-group-password-popover-item">You cannot reuse your previous password</div>
                        </div>
                    </div>
                </div>

                <base-input
                    :type="showNewPasswordType"
                    uid="newPasswordId"
                    name="newPassword"
                    v-model="newPassword"
                    ref="newPasswordInput"
                    v-fs-class="'reset-pw-input-answer'"
                    :validationErrors="validationErrorNewPassword"
                    :placeholder="'New password'"
                    v-test-prop="'reset-password-new'"
                    v-jest-class="'reset-password-new'"
                    @focus="showPasswordPopover = true; showErrorNewPasswordMatch = false"
                    @blur="showPasswordPopover = false"
                >
                    <template #prefix>
                        <icon-lock class="form-control-icon" :class="{'is-invalid': showErrorNewPassword}" width="16" />
                    </template>

                    <template #suffix>
                        <a class="form-group-show-pass" @click="showNewPassword = !showNewPassword" v-if="!showErrorNewPassword && newPassword.length > 0">{{showNewPassword ? 'Hide' : 'Show'}}</a>
                    </template>
                </base-input>
            </div>

            <div class="form-group with-icon" :class="{'is-invalid': showErrorNewPasswordRepeat}">
                <base-input
                    :type="showNewPasswordRepeatType"
                    uid="confirmPasswordId"
                    name="confirmPassword"
                    v-model="newPasswordRepeat"
                    ref="newPasswordInput"
                    v-fs-class="'reset-password-new-repeat'"
                    :validationErrors="validationErrorNewPasswordRepeat"
                    :placeholder="'Repeat password'"
                    v-test-prop="'reset-password-new-repeat'"
                    v-jest-class="'reset-password-new-repeat'"
                >
                    <template #prefix>
                        <icon-lock class="form-control-icon" :class="{'is-invalid': showNewPasswordRepeat}" width="16" />
                    </template>

                    <template #suffix>
                        <a class="form-group-show-pass" @click="showNewPasswordRepeat = !showNewPasswordRepeat" v-if="!showErrorNewPasswordRepeat && newPasswordRepeat.length > 0">{{showNewPasswordRepeat ? 'Hide' : 'Show'}}</a>
                    </template>
                </base-input>

                <span class="form-group-error" v-if="showErrorNewPasswordMatch">Password does not match</span>

            </div>

            <input type="hidden" value="true" name="changePasswordSubmitted">

            <div class="form-group">
                <a @click="continueStepOne" id="changePasswordButtonId" class="dh-btn-aqua submit-button">Continue</a>
            </div>
        </template>

        <template v-if="setupAccStep === 2">
            <!-- Step 2: Create a security question -->
            <div class="form-group with-icon" :class="{'is-invalid': showErrorQuestion}">
                <base-input
                    type="text"
                    uid="questionId"
                    name="question"
                    v-model="question"
                    v-fs-class="'setup-acc-question'"
                    :class="{'is-invalid': showErrorQuestion}"
                    :validationErrors="validationSecurityQuestion"
                    :placeholder="'Question'"
                    v-test-prop="'setup-acc-question'"
                    v-jest-class="'setup-acc-question'"
                >
                    <template #prefix>
                        <i class="form-control-icon" :class="{'is-invalid': showErrorQuestion}" width="16" >Q</i>
                    </template>
                </base-input>
            </div>

            <div class="form-group with-icon" :class="{'is-invalid': showErrorAnswer}">
                <base-input
                    type="text"
                    uid="answerId"
                    name="answer"
                    v-model="answer"
                    v-fs-class="'setup-acc-input-answer'"
                    :class="{'is-invalid': showErrorAnswer}"
                    :validationErrors="validationSecurityAnswer"
                    :placeholder="'Your answer'"
                    v-test-prop="'setup-acc-answer'"
                    v-jest-class="'setup-acc-answer'"
                >
                    <template #prefix>
                        <i class="form-control-icon" :class="{'is-invalid': showErrorAnswer}" width="16" >A</i>
                    </template>
                </base-input>
            </div>

            <input id="questionSubmittedId" name="questionSubmitted" type="hidden"  value="true"/>
            <input id="askMeNextTimeId" type="hidden" name="askMeNextTime" value="false"/>

            <div class="form-group">
                <a @click="continueStepTwo" class="dh-btn-aqua submit-button">Continue</a>
            </div>
        </template>

        <template v-if="setupAccStep === 3">
            <!-- Step 3: Accept license agreement -->
            <div class="form-group dh-checkbox with-border">
                <input type="checkbox" id="licenseAgreement" name="licenseAgreement" v-model="licenseAgreement">
                <label for="licenseAgreement"><strong>I agree to the C2P <a @click.prevent="showLicenseAgreement = true">license agreement</a></strong></label>
            </div>

            <div class="form-group">
                <a @click="continueStepThree" class="dh-btn-aqua submit-button" :class="{disabled: showErrorLicenseAgreement}">Done</a>
            </div>
        </template>

        <form-eula
            v-if="showLicenseAgreement"
            @close-popover="showLicenseAgreement = false"
        ></form-eula>
    </form>
</template>

<script>
    import FormEula from "./FormEula";
    import BaseInput from "../../../components/inputs/BaseInput.vue";
    import IconLock from "../../../components/icons/flat/IconLock.vue";
    import IconEmailEnvelope from "../../../components/icons/flat/IconEmailEnvelope.vue";

    export default {
        components: {FormEula, BaseInput, IconLock, IconEmailEnvelope},
        props: {
            urls: {
                type: Object
            },
            params: {
                type: [String, Object]
            }
        },
        data() {
            return {
                newPassword: '',
                showNewPasswordType: 'password',
                newPasswordRepeat: '',
                showNewPasswordRepeatType: 'password',
                question: '',
                answer: '',
                licenseAgreement: false,

                showErrorLogin: false,
                showErrorQuestion: false,
                validationSecurityQuestion: [],
                showErrorAnswer: false,
                validationSecurityAnswer: [],
                showErrorNewPassword: false,
                showErrorNewPasswordRepeat: false,
                showErrorNewPasswordMatch: false,
                showErrorLicenseAgreement: true,
                showLicenseAgreement: false,

                showPasswordPopover: false,

                validationErrorNewPassword: [],
                validationErrorNewPasswordRepeat: [],

                showNewPassword: false,
                showNewPasswordRepeat: false
            }
        },
        mounted() {
            if(this.params.loginErrors) {
                this.showErrorLogin = true
            }
            if(this.params.username) {
                this.username = this.params.username
            }
        },
        computed: {
            userId() {
                if(this.params.userId) {
                    return this.params.userId
                }
                return ''
            },
            setupAccStep() {
                let step = 1;
                if (this.params.form) {
                    if (this.params.form === 'questionAnswer') step = 2;
                    if (this.params.form === 'viewEula') step = 3;
                    if (this.params.form === 'resetPassword') step = 4;
                }
                return step;
            },
            postUrl() {
                if(this.setupAccStep === 2) {
                    return this.urls.securityQuestionUrl
                }

                if(this.setupAccStep === 3) {
                    return this.urls.eulaAcceptUrl
                }

                return this.urls.resetPasswordAction
            },
            getErrorMessage() {
                return this.params.loginErrors ? JSON.parse(this.params.loginErrors)[0] : ''
            },
            getFormTitle() {
                if(this.setupAccStep === 2) return 'Your account setup';
                if(this.setupAccStep === 3) return 'Your account setup';
                if(this.setupAccStep === 4) return 'You need to update your password';
                return 'Your account setup'
            },

            // New Password Validation
            checkForNoOfCharacters() {
                return this.newPassword.length >= 8;
            },
            checkForCharacters() {
                let regex = RegExp('[a-z]', 'i');
                return regex.test(this.newPassword);
            },
            checkForNumbers() {
                let regex = RegExp('[0-9]', 'i');
                return regex.test(this.newPassword);
            },
            checkForRepeatingCharacters() {
                let regex = RegExp('(.)\\1{2}', 'i');
                return !regex.test(this.newPassword);
            },
            isNewPasswordValid() {
                return this.checkForCharacters && this.checkForNumbers && this.checkForRepeatingCharacters && this.checkForNoOfCharacters
            }
        },
        methods: {
            continueStepOne() {
                if (!this.isNewPasswordValid) {
                    this.$refs.newPasswordInput.focus();
                    return false;
                }
                if (this.newPassword !== this.newPasswordRepeat) {
                    this.showErrorNewPasswordMatch = true;
                    return false;
                }

                this.$refs.form.submit();
            },
            continueStepTwo() {
                if (this.question === '') {
                    this.showErrorQuestion = true;
                    this.validationSecurityQuestion = ['required'];
                }
                if (this.answer === '') {
                    this.showErrorAnswer = true;
                    this.validationSecurityAnswer = ['required'];
                }

                if (this.question === '' || this.answer === '') return false;

                this.$refs.form.submit();
            },
            continueStepThree() {
                if (!this.licenseAgreement) {
                    this.showErrorLicenseAgreement = true;
                    return false;
                }

                this.$refs.form.submit();
            }
        },
        watch: {
            licenseAgreement(val) {
                if (val) this.showErrorLicenseAgreement = false;
            },
            question(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorQuestion = false;
                    this.validationSecurityQuestion = [];
                }
            },
            answer(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorAnswer = false;
                    this.validationSecurityAnswer = [];
                }
            },
            newPassword(val) {
                this.showErrorNewPasswordMatch = false;
            },
            newPasswordRepeat(val) {
                this.showErrorNewPasswordMatch = false;
                if (val && val.trim().length > 0) {
                    this.showErrorNewPasswordMatch = false;
                }
            },
            showNewPassword (val) {
                this.showNewPasswordType = val ? 'text' : 'password';
            },
            showNewPasswordRepeat(val) {
                this.showNewPasswordRepeatType = val ? 'text' : 'password';
            }
        }
    }
</script>