export const RelevanceAssessment = {
    Yes: 1,
    Maybe: 3,
    No: 2,
    Custom: 4,
    NotAssessed: -1
};

export const TypesOfAssessments = {
    onePerSource: 'ONE-PER-SOURCE',
    byResponsible: 'BY-RESPONSIBLE'
};

export const currentUserDisplayName = "Me";

export const RelevanceIconClasses = {
    yes: 'dh-icon-relevance-yes',
    maybe: 'dh-icon-relevance-maybe',
    no: 'dh-icon-relevance-no',
    custom: 'dh-icon-relevance-custom',
    unassessed: 'dh-icon-relevance-unassessed'
};

export const RelevanceIdCssMapDetails = (isResponsibleMe) => {
    return {
        [RelevanceAssessment.Yes]: {
            backgroundClass: "yes",
            iconClass: RelevanceIconClasses.yes
        },
        [RelevanceAssessment.Maybe]: {
            backgroundClass: "maybe",
            iconClass: RelevanceIconClasses.maybe
        },
        [RelevanceAssessment.No]: {
            backgroundClass: "no",
            iconClass: RelevanceIconClasses.no
        },
        [RelevanceAssessment.Custom]: {
            backgroundClass: isResponsibleMe ? "custom" : "yes",
            iconClass: isResponsibleMe ? RelevanceIconClasses.custom : RelevanceIconClasses.yes
        },
        [RelevanceAssessment.NotAssessed]: {
            backgroundClass: "unassessed",
            iconClass: RelevanceIconClasses.unassessed
        }
    }
};

export const RelevanceIdCssMapPreview = (isResponsibleMe) => {
    return {
        [RelevanceAssessment.Yes]: "dh-yes-small",
        [RelevanceAssessment.Maybe]: "dh-maybe-small",
        [RelevanceAssessment.No]: "dh-no-small",
        [RelevanceAssessment.Custom]: isResponsibleMe ? "dh-custom-small" : "dh-yes-small",
        [RelevanceAssessment.NotAssessed]: "dh-not-assessed-small dh-icon-minus"
    }
};

export const STATIC_RELEVANCE_MAP = {
    yes: {
        facetName: 'Yes',
        displayName: 'Relevant',
        count: 0,
        color: 'green',
        isSelected: false
    },
    maybe: {
        facetName: 'Maybe',
        displayName: 'Maybe',
        count: 0,
        color: 'orange',
        isSelected: false
    },
    no: {
        facetName: 'No',
        displayName: 'Not relevant',
        count: 0,
        color: 'black-50',
        isSelected: false
    },
    unassessed: {
        facetName: 'Unassessed',
        displayName: 'Unassessed',
        count: 0,
        color: 'black-10',
        isSelected: false
    }
}

export const StaticRelevances = [
    {
        count: 0,
        displayName: "Yes"
    },
    {
        count: 0,
        displayName: "Maybe"
    },
    {
        count: 0,
        displayName: "No"
    }
];

export const UnassessedRelevance = {
    count: 0,
    displayName: "Unassessed"
};

export const StaticRelevanceTypes = {
    "Yes": {
        "id": 1,
        "displayName": "Relevant",
        "systemName": "Yes"
    },
    "No": {
        "id": 2,
        "displayName": "Not Relevant",
        "systemName": "No"
    },
    "Maybe": {
        "id": 3,
        "displayName": "Maybe",
        "systemName": "Maybe"
    },
    "Custom": {
        "id": 0,
        "displayName": "Custom",
        "systemName": "custom"
    },
    "Unassessed": {
        "id": -1,
        "displayName": "Unassessed",
        "systemName": "unassessed"
    }
};

export const DateRangeFilterDates = [
    {
        label: 'All time',
        facet: 'all'
    }, {
        label: 'Last week',
        facet: 'lastW'
    }, {
        label: 'This month',
        facet: 'thisM'
    }, {
        label: 'Last month',
        facet: 'lastM'
    }, {
        label: 'This quarter',
        facet: 'thisQ'
    }, {
        label: 'Last quarter',
        facet: 'lastQ'
    }, {
        label: 'This year',
        facet: 'thisY'
    }, {
        label: 'Last year',
        facet: 'lastY'
    }
];

export const FileUploadAllowedTypes = {
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/jpg': '.jpg',
    'image/jpeg': '.jpeg',
    'video/mpeg': '.mpeg',
    'application/vnd.oasis.opendocument.presentation': '.odp',
    'application/vnd.oasis.opendocument.text': '.odt',
    'application/vnd.oasis.opendocument.spreadsheet': '.ods',
    'application/pdf': '.pdf',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
    'application/vnd.ms-powerpoint': '.ppt',
    'image/tiff': '.tif,.tiff',
    'text/plain': '.txt',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'audio/x-wav': '.wav,.wave'
};

export const FileIllegalCharacters = ['/', '\n', '\r', '\t', '\0', '\f', '`', '?', '*', '\\', '<', '>', '|', '\"', ':'];

export const SolrSearchOperators = {
    FieldOpExcludes: "FieldOpExcludes",
    FieldOpEquals: "FieldOpEquals",
    FieldOpEqualsWithChildren: "FieldOpEqualsWithChildren",
    FieldOpEqualsWithParents: "FieldOpEqualsWithParents",
    FieldOpEqualsWithParentsAndChildren: "FieldOpEqualsWithParentsAndChildren",
    FieldOpEqualsNoParentsAndChildren: "FieldOpEqualsNoParentsAndChildren" // only internal one
};

export const FacetTreeTooltipMap = {
    [SolrSearchOperators.FieldOpEquals]: "Does not include results of parent or subcategories",
    [SolrSearchOperators.FieldOpEqualsWithChildren]: "Includes results from subcategories of this item",
    [SolrSearchOperators.FieldOpEqualsWithParents]: "Includes results from the parent categories of this item",
    [SolrSearchOperators.FieldOpEqualsWithParentsAndChildren]: "Includes results from the parent category and the sub-categories of this item",
    [SolrSearchOperators.FieldOpEqualsNoParentsAndChildren]: "Does not include results of parent or subcategories"
};

export const StaticRegulationTypeFacet = [
    {
        displayName: "All results",
        facetName: "all"
    },
    {
        displayName: "Regulations",
        facetName: "Regulatory"
    },
    {
        displayName: "Standards",
        facetName: "Standard"
    },
    {
        displayName: "Supporting",
        facetName: "Supporting"
    }
];

export const RegulationTypes = {
    STANDARD: 'Standard',
    REGULATION: 'Regulatory',
    SUPPORTING: 'Supporting'
};

export const TestConstants = {
    CYPRESS_TEST_REF: 'data-cy',
    JEST_TEST_REF: 't',
    FULL_STORY_CLASS_PREFIX: 'fs'
};

export const HubInstallationName = 'C&R';

export const ColorPalette = ['rgb(0, 158, 115)', 'rgb(129,214,187)', 'rgb(252, 188, 124)', 'rgb(87, 148, 215)', 'rgb(138, 36, 52)', 'rgb(182, 183, 56)', 'rgb(185,59,110)', 'rgb(93,176,82)', 'rgb(220,229,158)', 'rgb(128,42,98)', 'rgb(110,76,55)', 'rgb(74,51,128)', 'rgb(123,115,37)', 'rgb(158,72,44)', 'rgb(61,119,227)', 'rgb(125,212,155)', 'rgb(113,77,133)', 'rgb(50,99,38)', 'rgb(255,176,246)', 'rgb(187,215,237)', 'rgb(147,97,232)', 'rgb(91,79,184)', 'rgb(212,120,112)', 'rgb(183,138,242)', 'rgb(148,127,93)', 'rgb(0,114,178)', 'rgb(227,210,54)', 'rgb(230,134,0)', 'rgb(89,184,240)', 'rgb(204,121,167)'];
export const ProductColorPalette = ['hsl(185, 58%, 88%)', 'hsl(2, 81%, 88%)', 'hsl(118, 65%, 90%)', 'hsl(21, 100%, 85%)', 'hsl(202, 20%, 87%)', 'hsl(40, 79%, 85%)', 'hsl(210, 65%, 90%)', 'hsl(59, 71%, 80%)', 'hsl(250, 100%, 94%)', 'hsl(80, 71%, 85%)', 'hsl(324, 71%, 93%)', 'hsl(160, 65%, 91%)'];
export const ProductColorDarkerPalette = ['hsl(185, 58%, 75%)', 'hsl(118, 65%, 80%)', 'hsl(21, 100%, 80%)', 'hsl(40, 79%, 80%)', 'hsl(210, 65%, 80%)', 'hsl(59, 71%, 80%)', 'hsl(250, 100%, 88%)', 'hsl(80, 71%, 78%)', 'hsl(324, 71%, 87%)', 'hsl(160, 65%, 83%)'];
export const OnboardingColorPalette = ['rgb(5, 197, 174)', 'rgb(80, 126, 233)'];

export const predictiveAnalyticsPageUrl = 'http://www.complianceandrisks.com/predictive-analytics-in-c2p/';

export const ALERT_TYPES = {
    regulations: {
        updated: 'digestSourceUpdatedInd',
        backFilled: 'digestSourcesBackfilledInd',
        statusChange: 'digestSourceStatusesInd',
        dates: 'digestKeyDatesInd',
        recurringDates: 'digestRecurringDatesInd',
    },
    requirements: {
        requirementToReview: 'digestRequirementToReviewInd',
        newUpdated: 'digestRequirementNewUpdateInd'
    }
}

export const USER_ROLES = {
    MANAGER: 'MANAGER',
    MONITOR: 'MONITOR',
    SME: 'SUBJECT_MATTER_EXPERT',
    PCE: 'PRODUCT_COMPLIANCE_ENGINEER'
}

export const USER_ROLES_ALERT_PREFERENCES = {
    [USER_ROLES.MANAGER]: [...Object.values(ALERT_TYPES.regulations)],
    [USER_ROLES.MONITOR]: [...Object.values(ALERT_TYPES.regulations)],
    [USER_ROLES.SME]: [...Object.values(ALERT_TYPES.regulations), ALERT_TYPES.requirements.requirementToReview],
    [USER_ROLES.PCE]: [ALERT_TYPES.requirements.newUpdated],
}

export const ESG_DOMAINS = {
    ENVIRONMENTAL: 'ENVIRONMENTAL',
    SOCIAL: 'SOCIAL',
    GOVERNANCE: 'GOVERNANCE'
}

export const SDG_IMAGES = {
    NO_POVERTY: 'NO_POVERTY',
    ZERO_HUNGER: 'ZERO_HUNGER',
    GOOD_HEALTH_AND_WELL_BEING: 'GOOD_HEALTH_AND_WELL_BEING',
    QUALITY_EDUCATION: 'QUALITY_EDUCATION',
    GENDER_EQUALITY: 'GENDER_EQUALITY',
    CLEAN_WATER_AND_SANITATION: 'CLEAN_WATER_AND_SANITATION',
    AFFORDABLE_AND_CLEAN_ENERGY: 'AFFORDABLE_AND_CLEAN_ENERGY',
    DECENT_WORK_AND_ECONOMIC_GROWTH: 'DECENT_WORK_AND_ECONOMIC_GROWTH',
    INDUSTRY_INNOVATION_AND_INFRASTRUCTURE: 'INDUSTRY_INNOVATION_AND_INFRASTRUCTURE',
    REDUCED_INEQUALITIES: 'REDUCED_INEQUALITIES',
    SUSTAINABLE_CITIES_AND_COMMUNITIES: 'SUSTAINABLE_CITIES_AND_COMMUNITIES',
    RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: 'RESPONSIBLE_CONSUMPTION_AND_PRODUCTION',
    CLIMATE_ACTION: 'CLIMATE_ACTION',
    LIFE_BELOW_WATER: 'LIFE_BELOW_WATER',
    LIFE_ON_LAND: 'LIFE_ON_LAND',
    PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: 'PEACE_JUSTICE_AND_STRONG_INSTITUTIONS',
    PARTNERSHIP_FOR_THE_GOAL: 'PARTNERSHIP_FOR_THE_GOAL',
}


export const GAINSIGHT_EVENT_NAMES = {
    SOURCE_SUMMARY_VISIT: 'source visit',
    REQUIREMENT_VISIT: 'Requirement visit',
    BATCH_ASSESSMENT: 'Batch assessment',
    SEARCH: 'C2PSearch',
    SHARE_SOURCE: 'Share',
    ASSESSMENT_NOTE_LENGTH: 'Assessment note length',
    AI_SOURCE_TRANSLATION: 'AI source translation'
}


export const GAINSIGHT_SEARCH_TYPES = {
    SPEC_DETAIL: 'SpecDetail',
    REQUIREMENTS: 'Requirements',
    LIBRARY: 'Library',
    INBOX: 'Inbox'
}

export const SPEC_ACCESS_TYPES = {
    NONE: 'None',
    FREE: 'Free',
    PAID: 'Paid',
}

export const AI_TRANSLATION = {
    FAILURE_MESSAGE: 'There was a problem with the translation. Please try again.',
    COMPLETED: 'Translation is complete. The file will download soon.',
    ALREADY_IN_PROGRESS: 'A Translation already in progress for another source',
    JOB_REQUEST_FAILED: 'Cannot request for AI translation at this time',
    DISCLAIMER_TOOLTIP: `By using this AI translation feature, you agree it uses automated AI technology without human intervention and may contain errors. Consult an attorney for legal matters and do not rely solely on these translations. This feature is provided "as-is" without warranties, and you use it at your own risk. You waive the right to hold C&R or its affiliates liable for any claims or damages from your use and agree to indemnify C&R against any third-party claims. This feature is in beta and subject to change. C&R may modify or discontinue it at any time without notice or liability. These terms apply to you and the entity you represent.`,
    STARTED: 'This will take a short time to process. The translation will download in your browser automatically once it’s completed.',
    UNSUPPORTED: 'This document is not currently supported for AI translations'
};
