var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside"
        }
      ],
      staticClass: "base-input",
      class: { "is--dirty": _vm.hasChanged }
    },
    [
      _vm.label
        ? _c("label", { staticClass: "form-label", attrs: { for: _vm.uid } }, [
            _vm._v("\n        " + _vm._s(_vm.label) + " "),
            !_vm.required ? _c("span", [_vm._v("(Optional)")]) : _vm._e(),
            _vm._v(" "),
            _vm.hasHelpIcon
              ? _c(
                  "span",
                  { staticClass: "form-label--help" },
                  [_vm._t("helpIcon")],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPrefix || _vm.hasSuffix
        ? _c(
            "div",
            {
              staticClass: "input-group",
              class: [
                {
                  "is--highlighted":
                    _vm.hasPopulatedValue && !_vm.disableHighlight,
                  "is--focused": _vm.isFocused
                },
                { "is-invalid": _vm.validationErrors.length > 0 }
              ]
            },
            [
              _vm.hasPrefix
                ? _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_vm._t("prefix")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _vm.getComponent,
                _vm._b(
                  {
                    ref: "input",
                    tag: "component",
                    class: { "is-invalid": _vm.validationErrors.length > 0 },
                    on: {
                      input: _vm.handleInput,
                      keypress: function($event) {
                        return _vm.$emit("keypress", $event)
                      },
                      focus: _vm.handleFocus
                    }
                  },
                  "component",
                  _vm.$props,
                  false
                )
              ),
              _vm._v(" "),
              _vm.hasSuffix
                ? _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [_vm._t("suffix")],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasPrefix && !_vm.hasSuffix
        ? _c(
            _vm.getComponent,
            _vm._b(
              {
                ref: "input",
                tag: "component",
                class: { "is-invalid": _vm.validationErrors.length > 0 },
                on: {
                  input: _vm.handleInput,
                  keypress: function($event) {
                    return _vm.$emit("keypress", $event)
                  },
                  focus: _vm.handleFocus
                }
              },
              "component",
              _vm.$props,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "invalid-feedback" }, [
        _vm._v(_vm._s(_vm.validationErrors.join(", ")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }