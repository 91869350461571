import Vue from "vue";
import {TestConstants} from "../Constants";

/**
 * Sets jest test class on element
 * @value css class name
 * @return Css class containing value you passed - eg. t-save-new-search-button
 * @example v-jest-class="'save-new-search-button'"
 */
export default Vue.directive('JestClass', (el, bindings, vNode) => {
    // value
    let value = bindings.value.toLowerCase().split(' ');
    if(!value || value.length === 0) {
        console.error("JestClass directive: Missing value");
        return false
    }
    
    // transform to array
    if (!Array.isArray(value)) {
        value = [value]
    }
    
    // Add FS css classes to element
    for (let v of value) {
        el.classList.add(`${TestConstants.JEST_TEST_REF}-${v}`)
    }
});