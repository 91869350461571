var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-form-eula" }, [
    _c("div", { staticClass: "login-form-eula-header" }, [
      _c("div", { staticClass: "login-form-eula-header-title" }, [
        _vm._v("End User License Agreement")
      ]),
      _vm._v(" "),
      _c("a", {
        staticClass: "login-form-eula-header-close dh-icon-x-sm",
        on: {
          click: function($event) {
            return _vm.$emit("close-popover")
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "login-form-eula-body" },
      [_c("end-user-license-agreement")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }