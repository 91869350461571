var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "quillEditorContainer",
      staticClass: "quill-editor",
      class: { "is--focused": _vm.isFocused }
    },
    [
      _vm.showCustomToolbar
        ? _c(
            "div",
            { ref: "quillToolbar", attrs: { id: "quillToolbar" + _vm.uid } },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "ql-formats" }, [
                _c("div", { attrs: { id: "quill-custom-actions" } }),
                _vm._v(" "),
                _c("button", { staticClass: "ql-tableUI" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        version: "1.1",
                        id: "Capa_1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 512 512",
                        "xml:space": "preserve"
                      }
                    },
                    [
                      _c("g", [
                        _c("g", [
                          _c("path", {
                            attrs: {
                              d:
                                "M452,50H60C26.916,50,0,76.916,0,110v292c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V110\n\t\t\tC512,76.916,485.084,50,452,50z M156,422H60c-11.028,0-20-8.972-20-20v-62h116V422z M156,300H40v-88h116V300z M156,172H40v-62\n\t\t\tc0-11.028,8.972-20,20-20h96V172z M317,422H196v-82h121V422z M317,300H196v-88h121V300z M317,172H196V90h121V172z M472,402\n\t\t\tc0,11.028-8.972,20-20,20h-95v-82h115V402z M472,300H357v-88h115V300z M472,172H357V90h95c11.028,0,20,8.972,20,20V172z"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(3)
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        ref: "quillEditor",
        domProps: { innerHTML: _vm._s(_vm.value) }
      })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-bold" }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-italic" }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-underline" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ql-formats" }, [
      _c("select", { staticClass: "ql-header" }, [
        _c("option", { attrs: { value: "1" } }, [_vm._v("Header 1")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2" } }, [_vm._v("Header 2")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3" } }, [_vm._v("Header 3")]),
        _vm._v(" "),
        _c("option", { attrs: { selected: "" } }, [_vm._v("Normal")])
      ]),
      _vm._v(" "),
      _c("select", { staticClass: "ql-color" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ql-formats" }, [
      _c("select", { staticClass: "ql-align" }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-indent", attrs: { value: "-1" } }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-indent", attrs: { value: "+1" } }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-list", attrs: { value: "ordered" } }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-list", attrs: { value: "bullet" } }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-blockquote" }),
      _vm._v(" "),
      _c("button", { staticClass: "ql-link" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ql-formats" }, [
      _c("button", { staticClass: "ql-undo undo" }, [
        _c("i", { staticClass: "dh-icon-undo" })
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "ql-redo redo" }, [
        _c("i", { staticClass: "dh-icon-undo" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }