export const QuillDefaultOptions = {
    theme: 'snow',
    boundary: document.body,
    modules: {
        toolbar: '#quillToolbar',
        clipboard: {
            matchVisual: false
        },
        table: true,
        tableUI: true,
    },
    placeholder: 'Add description'
}