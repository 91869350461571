import axios from 'axios';
import DOMPurify from "dompurify";

let moment = require('moment');

export class LibraryRegulationListPayload {
    constructor() {
        this.start = "0";
        this.length = "30";
        this.draw = "1";
        this.sortType = "Popularity";
        this.searchString = "";
        this.jsonFilterMap = "";
        this.searchScope = "COMPANY_ALERTS";
    }
}

export class DateRanges {
    constructor(serverTime) {
        if (!serverTime) serverTime = new Date();
        let serverTimeFormatted = moment(new Date(serverTime).toISOString()).format('YYYY-MM-DD');
        return {
            'lastW': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(7, 'd').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                'Last week'
            ],
            'thisM': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'),
                'This month'
            ],
            'lastM': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
                'Last month'
            ],
            'thisQ': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').startOf('quarter').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').endOf('quarter').format('YYYY-MM-DD'),
                'This quarter'
            ],
            'lastQ': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
                'Last quarter'
            ],
            'thisY': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').startOf('year').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').endOf('year').format('YYYY-MM-DD'),
                'This year'
            ],
            'lastY': [
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
                'Last year'
            ],
            'all': [
                moment.utc('2002-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD'),
                moment.utc(serverTimeFormatted, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'),
                'All time'
            ],
        };
    }
}

export function convertBodyToParams(data) {
    const str = [];
    for (let p in data) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
    }
    return str.join("&");
}

export function formatDate(date, showYear) {
    let dateObj = date.replace(',', '').split(' ');
    return showYear ? dateObj[0] + ' ' + dateObj[1] + ', ' + dateObj[2] : dateObj[0] + ' ' + dateObj[1];
}

export function handleErrorResponse(e) {
    if (!axios.isCancel(e)) {
        console.error(e);
        const error = e ? JSON.parse(JSON.stringify(e)) : null;
        if (error && error.response && error.response.status === 401) {
            window.location.reload();
        }
    }
}

export function getQueryString() {
    var key = false, res = {}, itm = null;
    // get the query string without the ?
    var qs = location.search.substring(1);
    // check for the key as an argument
    if (arguments.length > 0 && arguments[0].length > 1)
        key = arguments[0];
    // make a regex pattern to grab key/value
    var pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    while (itm = pattern.exec(qs)) {
        if (key !== false && decodeURIComponent(itm[1]) === key)
            return decodeURIComponent(itm[2]);
        else if (key === false)
            res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
    }

    return key === false ? res : null;
}

export function CopyToClipboard(text) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function GenerateRandomHSLColor() {
    let randomHue = (Math.random() * 360 << 0);

    let hueDifference = randomHue - 176;
    if (Math.abs(hueDifference) < 15) {
        // Shift the hue away from our C&R color hsl(176, 61%, 55%)
        if (hueDifference > 0)
            randomHue += 24 + (Math.random() * 100 << 0)
        else if (hueDifference < 0)
            randomHue -= 26 + (Math.random() * 150 << 0)
    }

    let randomSaturation = 70 + (Math.random() * 22 << 0);
    let randomLightness = 50 + (Math.random() * 30 << 0);

    return "hsla(" + randomHue + " , " + randomSaturation + "%, " + randomLightness + "%, .2)";
}

export function ConvertPlainTextLinksToHyperlinks(text) {
    return (text || "").replace(
        /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
        (match, space, url) => {
            let hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
        }
    );
}

export const SanitizeHtml_AllowedTags = ['b', 'br']
export const SanitizeHtml_AllowedAttributes = ['class', 'style']
export const SanitizeHtml = (val, ALLOWED_TAGS = SanitizeHtml_AllowedTags, ALLOWED_ATTR = SanitizeHtml_AllowedAttributes) => {
    if (!val) val = ''
    return DOMPurify.sanitize(val, {ALLOWED_TAGS, ALLOWED_ATTR})
}

export function resolvePageNameFromUrl(pageUrl) {
    if (pageUrl.includes('c2png/alert/my-alerts')) return 'Inbox';
    else if (pageUrl.includes('c2png/alert/news-analysis')) return 'Inbox';
    else if (pageUrl.includes('c2png/alert/upcoming-dates')) return 'Inbox';
    else if (pageUrl.includes('c2png/alert/tasks')) return 'Inbox';
    else if (pageUrl.includes('c2png/library')) return 'Library';
    else if (pageUrl.includes('c2png/requirements')) return 'Requirements';
    else if (pageUrl.includes('c2png/requirement?')) return 'Requirement';
    else if (pageUrl.includes('c2png/source?')) return 'Regulation';
    else if (pageUrl.includes('c2png/evidence')) return 'Evidence';
    else if (pageUrl.includes('c2png/dashboard')) return 'Dashboard';
    else if (pageUrl.includes('c2png/ask-our-experts')) return 'Ask Our Experts';
}

export function shouldShowStPatrickTheme() {
    const currentDate = new Date().getTime()
    const stPatrickDayStart = new Date(new Date().getFullYear(), 2, 13).getTime()
    const stPatrickDayEnd = new Date(new Date().getFullYear(), 2, 18).getTime()
    return (currentDate >= stPatrickDayStart && currentDate <= stPatrickDayEnd)
}

export const ClearHtmlTags = (string) => {
    let temp = document.createElement("div");
    temp.innerHTML = string;
    return SanitizeHtml(temp.textContent || temp.innerText);
}

export const validateUrl = string => string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) !== null

export const generateKeyFromString = string => string.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()