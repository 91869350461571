import Vue from "vue";
import {TestConstants} from "../Constants";

/**
 * Sets full story class on element
 * @modifier {page} Add modifier to prepend page name to css class
 * @value event css class name
 * @return Css class containing fs prefix, modifier and value you passed - eg. fs-inbox-save-new-search-button
 * @example v-fs-class.page="'save-new-search-button'"
 */
export default Vue.directive('FsClass', (el, bindings, vNode) => {
    // css class
    let analyticsClass = TestConstants.FULL_STORY_CLASS_PREFIX + "-";
    
    // value
    const value = bindings.value.toLowerCase();
    if (!value || value.trim().length === 0) {
        console.error("fsAnalytics directive: Missing value");
        return false
    }
    
    // Get modifiers
    const modifiers = bindings.modifiers;
    
    // Check if pageSpecific
    if (modifiers && modifiers['page'] && vNode && vNode.context) {
        const vm = vNode.context;
        const pageName = vm.pageName;
        if (pageName) {
            const pageNameFormatted = pageName.replace(/\s/g, '').replace(/:\s*/g, "-").toLowerCase().replace('c2pnew-', '');
            analyticsClass += `${pageNameFormatted}-`;
        }
    }
    
    // Check if multiple values passed
    let listOfValues = value.split(' ')
    if (listOfValues && listOfValues.length > 0) {
        for (let val of listOfValues) {
            // Add FS css class to element
            el.classList.add(analyticsClass + val);
        }
    } else {
        // Add value
        analyticsClass += value;
        
        // Add FS css class to element
        el.classList.add(analyticsClass);
    }
});