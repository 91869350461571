var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "login-form",
      attrs: { action: _vm.urls.loginUrl, method: "post" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "login-form-logo" }),
      _vm._v(" "),
      _vm.getErrorMessage
        ? _c("div", {
            staticClass: "login-form-failed-msg",
            domProps: { innerHTML: _vm._s(_vm.getErrorMessage) }
          })
        : _c("div", { staticClass: "login-form-title" }, [
            _vm._v("Log in to C2P")
          ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-group with-icon",
          class: {
            "is-invalid": _vm.showErrorUsername,
            filled: _vm.username.length > 0
          }
        },
        [
          _c("base-input", {
            directives: [
              {
                name: "fs-class",
                rawName: "v-fs-class",
                value: "login-username",
                expression: "'login-username'"
              }
            ],
            attrs: {
              type: "text",
              uid: "usernameId",
              name: "username",
              validationErrors: _vm.validationErrorUsername,
              placeholder: "Username"
            },
            scopedSlots: _vm._u([
              {
                key: "prefix",
                fn: function() {
                  return [
                    _c("icon-user", {
                      staticClass: "form-control-icon",
                      attrs: { width: "16" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = $$v
              },
              expression: "username"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-group with-icon",
          class: {
            "is-invalid": _vm.showErrorPassword,
            filled: _vm.password.length > 0
          }
        },
        [
          _c("base-input", {
            directives: [
              {
                name: "fs-class",
                rawName: "v-fs-class",
                value: "login-password",
                expression: "'login-password'"
              }
            ],
            attrs: {
              type: _vm.showPasswordType,
              uid: "passwordId",
              name: "password",
              validationErrors: _vm.validationErrorPassword,
              placeholder: "Password"
            },
            scopedSlots: _vm._u([
              {
                key: "prefix",
                fn: function() {
                  return [
                    _c("icon-lock", {
                      staticClass: "form-control-icon",
                      class: { "is-invalid": _vm.showErrorPassword },
                      attrs: { width: "16" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "suffix",
                fn: function() {
                  return [
                    !_vm.showErrorPassword && _vm.password.length > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "form-group-show-pass",
                            on: {
                              click: function($event) {
                                _vm.showPassword = !_vm.showPassword
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.showPassword ? "Hide" : "Show"))]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group dh-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.keepMeLoggedIn,
              expression: "keepMeLoggedIn"
            }
          ],
          attrs: {
            type: "checkbox",
            id: "keepMeLoggedInId",
            name: "keepMeLoggedIn"
          },
          domProps: {
            checked: Array.isArray(_vm.keepMeLoggedIn)
              ? _vm._i(_vm.keepMeLoggedIn, null) > -1
              : _vm.keepMeLoggedIn
          },
          on: {
            change: function($event) {
              var $$a = _vm.keepMeLoggedIn,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.keepMeLoggedIn = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.keepMeLoggedIn = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.keepMeLoggedIn = $$c
              }
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "keepMeLoggedInId" } }, [
          _vm._v("Keep me logged in for longer.")
        ]),
        _vm._v(" "),
        _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value:
                "We will remember your login <br/>details. For security reasons we <br/>will periodically require login.",
              expression:
                "'We will remember your login <br/>details. For security reasons we <br/>will periodically require login.'"
            }
          ],
          staticClass: "dh-icon-circle-info"
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "dh-btn-aqua submit-button",
            attrs: { type: "submit", id: "loginbuttonId" },
            on: { click: _vm.onSubmit }
          },
          [_vm._v("Log in")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-form-footer" }, [
        _c(
          "a",
          {
            staticClass: "login-form-footer-link",
            attrs: {
              href: _vm.urls.forgotPasswordUrl,
              id: "forgotPasswordUrlId"
            }
          },
          [_vm._v("Forgot your password?")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }