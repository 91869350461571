<template>
    <div>
        <quill-editor
            :value="initialValue"
            :options="getEditorOptions"
            :disabled="disabled"
            :uid="uid"
            v-test-prop="`${uid}-input`"
            @change="quillUpdate"
        />
    </div>
</template>

<script>
import QuillEditor from "../../../shared/quill-editor/QuillEditor";
import {QuillDefaultOptions} from "../../../shared/quill-editor/QuillConstants";

export default {
    components: {QuillEditor},
    props: {
        uid: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Add description'
        },
        value: {
            type: String
        },
        tabindex: String
    },
    data: () => ({
        initialValue: ''
    }),
    created() {
        this.initialValue = this.value
    },
    computed: {
        getEditorOptions() {
            return {
                ...QuillDefaultOptions,
                modules: {
                    ...QuillDefaultOptions.modules,
                    toolbar: [['bold', 'italic', 'underline'], [{'indent': '-1'}, {'indent': '+1'}, {'list': 'ordered'}, {'list': 'bullet'}], ['blockquote', 'link']],
                    table: false,
                    tableUI: false
                }
            }
        },
    },
    methods: {
        quillUpdate({html}) {
            this.$emit('input', html)
        },
    }
}
</script>