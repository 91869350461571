<template>
    <svg class="svg-icon" :class="'icon--c-'+color" viewBox="0 0 16 16" :width="width" height="100%" preserverAspectRatio="xMinYMid">
        <path d="M3 7V14H13V7H3ZM8 0C10.2091 0 12 1.79086 12 4V5H13C14.1046 5 15 5.89543 15 7V14C15 15.1046 14.1046 16 13 16H3C1.89543 16 1 15.1046 1 14V7C1 5.89543 1.89543 5 3 5H4V4C4 1.79086 5.79086 0 8 0ZM8 2C6.89543 2 6 2.89543 6 4V4.99898L6.01605 4.99923L8.96296 4.99993C9.02881 4.99997 9.09282 5.00001 9.1546 5.00005L9.76264 5.00099C9.91221 5.00145 10 5.00207 10 5.00291V4C10 2.89543 9.10457 2 8 2Z"/>
    </svg>

</template>

<script>
import iconDefault from "./mixins/_iconDefault.js";

export default {
    mixins: [iconDefault],
    name: 'icon-lock'
}
</script>