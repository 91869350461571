var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input-mask", {
    directives: [
      {
        name: "test-prop",
        rawName: "v-test-prop",
        value: "input-date",
        expression: "`input-date`"
      }
    ],
    staticClass: "form-control",
    attrs: {
      mask: "aaa{ }00{, }0000",
      lazy: true,
      placeholder: _vm.placeholder ? _vm.placeholder : _vm.defaultPlaceholder,
      value: _vm.modelValue,
      tabindex: _vm.tabindex,
      disabled: _vm.disabled,
      readonly: _vm.readonly
    },
    on: {
      input: _vm.handleDatePickerInput,
      complete: _vm.handleDatePickerChange,
      click: function($event) {
        return _vm.$emit("focus", true)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }