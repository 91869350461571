var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "login-form",
      attrs: { action: _vm.postUrl, method: "post" }
    },
    [
      _c("div", { staticClass: "login-form-logo" }),
      _vm._v(" "),
      _vm.showErrorLogin
        ? _c("div", { staticClass: "login-form-failed-msg" }, [
            _vm._v("\n        " + _vm._s(_vm.getErrorMessage) + "\n    ")
          ])
        : _c("div", { staticClass: "login-form-title" }, [
            _c("div", [_vm._v(_vm._s(_vm.getFormTitle))]),
            _vm._v(" "),
            _c("ul", { staticClass: "login-form-title-list" }, [
              _vm.setupAccStep === 1
                ? _c("li", [_vm._v("Create new password")])
                : _vm._e(),
              _vm._v(" "),
              _vm.setupAccStep === 2
                ? _c("li", [_vm._v("Create a security question")])
                : _vm._e(),
              _vm._v(" "),
              _vm.setupAccStep === 3
                ? _c("li", [_vm._v("Accept license agreement")])
                : _vm._e(),
              _vm._v(" "),
              _vm.setupAccStep === 4
                ? _c("li", [_vm._v("Please enter a new password")])
                : _vm._e()
            ])
          ]),
      _vm._v(" "),
      _vm.setupAccStep === 1 || _vm.setupAccStep === 4
        ? [
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorNewPassword }
              },
              [
                _vm.showPasswordPopover
                  ? _c("div", { staticClass: "form-group-password-popover" }, [
                      _c(
                        "div",
                        { staticClass: "form-group-password-popover-inner" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group-password-popover-title"
                            },
                            [_vm._v("Password must")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForNoOfCharacters
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least 8 characters")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForCharacters
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least one letter")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForNumbers
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least one number")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForRepeatingCharacters
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v(
                                  "Cannot contain three repeating characters"
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0)
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "reset-pw-input-answer",
                      expression: "'reset-pw-input-answer'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "reset-password-new",
                      expression: "'reset-password-new'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "reset-password-new",
                      expression: "'reset-password-new'"
                    }
                  ],
                  ref: "newPasswordInput",
                  attrs: {
                    type: _vm.showNewPasswordType,
                    uid: "newPasswordId",
                    name: "newPassword",
                    validationErrors: _vm.validationErrorNewPassword,
                    placeholder: "New password"
                  },
                  on: {
                    focus: function($event) {
                      _vm.showPasswordPopover = true
                      _vm.showErrorNewPasswordMatch = false
                    },
                    blur: function($event) {
                      _vm.showPasswordPopover = false
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-lock", {
                              staticClass: "form-control-icon",
                              class: { "is-invalid": _vm.showErrorNewPassword },
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "suffix",
                        fn: function() {
                          return [
                            !_vm.showErrorNewPassword &&
                            _vm.newPassword.length > 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "form-group-show-pass",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewPassword = !_vm.showNewPassword
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showNewPassword ? "Hide" : "Show"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2715583835
                  ),
                  model: {
                    value: _vm.newPassword,
                    callback: function($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorNewPasswordRepeat }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "reset-password-new-repeat",
                      expression: "'reset-password-new-repeat'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "reset-password-new-repeat",
                      expression: "'reset-password-new-repeat'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "reset-password-new-repeat",
                      expression: "'reset-password-new-repeat'"
                    }
                  ],
                  ref: "newPasswordInput",
                  attrs: {
                    type: _vm.showNewPasswordRepeatType,
                    uid: "confirmPasswordId",
                    name: "confirmPassword",
                    validationErrors: _vm.validationErrorNewPasswordRepeat,
                    placeholder: "Repeat password"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-lock", {
                              staticClass: "form-control-icon",
                              class: {
                                "is-invalid": _vm.showNewPasswordRepeat
                              },
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "suffix",
                        fn: function() {
                          return [
                            !_vm.showErrorNewPasswordRepeat &&
                            _vm.newPasswordRepeat.length > 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "form-group-show-pass",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewPasswordRepeat = !_vm.showNewPasswordRepeat
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showNewPasswordRepeat
                                          ? "Hide"
                                          : "Show"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1331338179
                  ),
                  model: {
                    value: _vm.newPasswordRepeat,
                    callback: function($$v) {
                      _vm.newPasswordRepeat = $$v
                    },
                    expression: "newPasswordRepeat"
                  }
                }),
                _vm._v(" "),
                _vm.showErrorNewPasswordMatch
                  ? _c("span", { staticClass: "form-group-error" }, [
                      _vm._v("Password does not match")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                value: "true",
                name: "changePasswordSubmitted"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  attrs: { id: "changePasswordButtonId" },
                  on: { click: _vm.continueStepOne }
                },
                [_vm._v("Continue")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.setupAccStep === 2
        ? [
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorQuestion }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "setup-acc-question",
                      expression: "'setup-acc-question'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "setup-acc-question",
                      expression: "'setup-acc-question'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "setup-acc-question",
                      expression: "'setup-acc-question'"
                    }
                  ],
                  class: { "is-invalid": _vm.showErrorQuestion },
                  attrs: {
                    type: "text",
                    uid: "questionId",
                    name: "question",
                    validationErrors: _vm.validationSecurityQuestion,
                    placeholder: "Question"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c(
                              "i",
                              {
                                staticClass: "form-control-icon",
                                class: { "is-invalid": _vm.showErrorQuestion },
                                attrs: { width: "16" }
                              },
                              [_vm._v("Q")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2755547746
                  ),
                  model: {
                    value: _vm.question,
                    callback: function($$v) {
                      _vm.question = $$v
                    },
                    expression: "question"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorAnswer }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "setup-acc-input-answer",
                      expression: "'setup-acc-input-answer'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "setup-acc-answer",
                      expression: "'setup-acc-answer'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "setup-acc-answer",
                      expression: "'setup-acc-answer'"
                    }
                  ],
                  class: { "is-invalid": _vm.showErrorAnswer },
                  attrs: {
                    type: "text",
                    uid: "answerId",
                    name: "answer",
                    validationErrors: _vm.validationSecurityAnswer,
                    placeholder: "Your answer"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c(
                              "i",
                              {
                                staticClass: "form-control-icon",
                                class: { "is-invalid": _vm.showErrorAnswer },
                                attrs: { width: "16" }
                              },
                              [_vm._v("A")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1852773600
                  ),
                  model: {
                    value: _vm.answer,
                    callback: function($$v) {
                      _vm.answer = $$v
                    },
                    expression: "answer"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              attrs: {
                id: "questionSubmittedId",
                name: "questionSubmitted",
                type: "hidden",
                value: "true"
              }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                id: "askMeNextTimeId",
                type: "hidden",
                name: "askMeNextTime",
                value: "false"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  on: { click: _vm.continueStepTwo }
                },
                [_vm._v("Continue")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.setupAccStep === 3
        ? [
            _c("div", { staticClass: "form-group dh-checkbox with-border" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.licenseAgreement,
                    expression: "licenseAgreement"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  id: "licenseAgreement",
                  name: "licenseAgreement"
                },
                domProps: {
                  checked: Array.isArray(_vm.licenseAgreement)
                    ? _vm._i(_vm.licenseAgreement, null) > -1
                    : _vm.licenseAgreement
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.licenseAgreement,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.licenseAgreement = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.licenseAgreement = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.licenseAgreement = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "licenseAgreement" } }, [
                _c("strong", [
                  _vm._v("I agree to the C2P "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showLicenseAgreement = true
                        }
                      }
                    },
                    [_vm._v("license agreement")]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  class: { disabled: _vm.showErrorLicenseAgreement },
                  on: { click: _vm.continueStepThree }
                },
                [_vm._v("Done")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showLicenseAgreement
        ? _c("form-eula", {
            on: {
              "close-popover": function($event) {
                _vm.showLicenseAgreement = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group-password-popover-note" }, [
      _c("div", { staticClass: "form-group-password-popover-item" }, [
        _vm._v("You cannot reuse your previous password")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }