<template>
    <div class="base-input"
         :class="{'is--dirty': hasChanged}"
         v-on-clickaway="handleClickOutside"
    >
        <label
            v-if="label"
            :for="uid"
            class="form-label"
        >
            {{ label }} <span v-if="!required">(Optional)</span>
            <span v-if="hasHelpIcon" class="form-label--help">
                <slot name="helpIcon"></slot>
            </span>
        </label>
        <div class="input-group"
             v-if="hasPrefix || hasSuffix"
             :class="[
                 {'is--highlighted': hasPopulatedValue && !disableHighlight, 'is--focused': isFocused},
                 {'is-invalid': validationErrors.length > 0}
             ]"
        >
            <span v-if="hasPrefix" class="input-group-text">
                <slot name="prefix"></slot>
            </span>
            <component
                :is="getComponent"
                v-bind="$props"
                ref="input"
                :class="{'is-invalid': validationErrors.length > 0}"
                @input="handleInput"
                @keypress="$emit('keypress', $event)"
                @focus="handleFocus"
            />
            <span class="input-group-text" v-if="hasSuffix">
                <slot name="suffix"></slot>
            </span>
        </div>
        <component
            v-if="!hasPrefix && !hasSuffix"
            :is="getComponent"
            v-bind="$props"
            ref="input"
            :class="{'is-invalid': validationErrors.length > 0}"
            @input="handleInput"
            @keypress="$emit('keypress', $event)"
            @focus="handleFocus"
        />
        <div class="invalid-feedback">{{ validationErrors.join(', ') }}</div>
    </div>
</template>

<script>
import InputDate from "./input-types/InputDate";
import InputText from "./input-types/InputText";
import InputTextarea from "./input-types/InputTextarea";
import InputNumber from "./input-types/InputNumber";
import InputRichText from "./input-types/InputRichText";
import InputPassword from "./input-types/InputPassword";
import {INPUT_SIZES, INPUT_TYPES, INPUT_TYPE_COMPONENTS} from "./InputConstants";

export default {
    components: {InputDate, InputText, InputTextarea, InputNumber, InputRichText, InputPassword},
    props: {
        uid: {
            type: String,
            required: true
        },
        tabindex: {
            type: String
        },
        type: {
            type: String,
            validator: val => Object.values(INPUT_TYPES).includes(val),
            default: INPUT_TYPES.text
        },
        size: {
            type: String,
            validator: val => Object.values(INPUT_SIZES).includes(val),
            default: INPUT_SIZES.default
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        label: String,
        value: {
            type: [String, Number]
        },
        hasValueFn: {
            type: Function
        },
        disableHighlight: {
            type: Boolean,
            default: false
        },
        validationErrors: {
            type: Array,
            default: () => []
        },
        disableDirty: {
            type: Boolean,
            default: false
        },
        dateFormat: {
            type: String
        },
        name: {
            type: String
        }
    },

    data: () => ({
        copyOfValue: null,
        isFocused: false
    }),

    created() {
        this.copyOfValue = this.value
    },

    computed: {
        hasChanged() {
            if (this.disableDirty) return false
            return this.copyOfValue !== this.value
        },
        getComponent() {
            return INPUT_TYPE_COMPONENTS[this.type]
        },
        hasPrefix() {
            return !!this.$slots['prefix']
        },
        hasSuffix() {
            return !!this.$slots['suffix']
        },
        hasHelpIcon() {
            return !!this.$slots['helpIcon']
        },
        hasPopulatedValue() {
            if (this.hasValueFn && typeof this.hasValueFn === "function") return this.hasValueFn(this.value)
            if (Array.isArray(this.value) || typeof this.value === "string") return this.value.length > 0
            return !!this.value
        }
    },
    methods: {
        setFocus() { // Used by parent component
            if (this.$refs.input && this.$refs.input.setFocus) this.$refs.input.setFocus()
        },
        handleFocus(e) {
            this.isFocused = e
            if (e) this.$emit('focus')
            else this.$emit('blur')
        },
        handleInput(val) {
            if (this.type === INPUT_TYPES.number) {
                const value = val.length > 0 ? Number(val) : null
                this.$emit('input', value)
            }
            else this.$emit('input', val)
        },
        handleClickOutside() {
            if (this.type === 'date') {
                this.handleFocus(false)
            }
        }
    }
}
</script>