import Quill from 'quill'
const Parchment = Quill.import('parchment')

const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const multiplier = 20;

class IndentAttributor extends Parchment.StyleAttributor {
    constructor(attrName, attr, options) {
        super(attrName, attr, options)
    }
    
    add(node, value) {
        return super.add(node, `${value * multiplier}px`);
    }
    
    value(node) {
        return parseFloat(super.value(node)) / multiplier || undefined;
    }
}

const IndentFormat = new IndentAttributor('indent', 'margin-left', {
    scope: Parchment.Scope.BLOCK,
    whitelist: levels.map(value => `${value * multiplier}px`)
})

export default IndentFormat