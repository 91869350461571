<template>
    <form class="login-form" :ref="'form'" :action="postUrl" method="post">
        <div class="login-form-logo"></div>

        <div class="login-form-failed-msg" v-if="showErrorLogin" v-html="getErrorMessage" />

        <div v-else class="login-form-title">
            <div>{{getFormName}}</div>

            <ul class="login-form-title-list" v-if="showForgotSteps">
                <li :class="{active: resetPassStep === 1}">1. Enter your username and email</li>
                <li :class="{active: resetPassStep === 2}">2. Answer your security question</li>
                <li :class="{active: resetPassStep === 3}">3. Enter new password</li>
            </ul>
        </div>

        <template v-if="resetPassStep === 1">
            <!-- Step 1: Enter your username and email -->
            <div class="form-group" :class="{'is-invalid': showErrorUsername}">
                <base-input
                    type="text"
                    uid="usernameId"
                    name="username"
                    v-model="username"
                    v-fs-class="'forgot-pw-input-username'"
                    :validationErrors="validationErrorUsername"
                    :placeholder="'Username'"
                    v-test-prop="'forgot-password-username'"
                    v-jest-class="'app-header-search-box'"
                >
                    <template #prefix>
                        <icon-user class="form-control-icon" width="16" />
                    </template>
                </base-input>
            </div>

            <div class="form-group " :class="{'is-invalid': showErrorEmail}">
                <base-input
                    type="text"
                    uid="emailId"
                    name="email"
                    v-model="email"
                    v-fs-class="'forgot-pw-input-email'"
                    :validationErrors="validationErrorEmail"
                    :placeholder="'Email address'"
                    v-test-prop="'forgot-password-email'"
                    v-jest-class="'app-header-search-box'"
                >
                    <template #prefix>
                        <icon-email-envelope class="form-control-icon" :class="{'is-invalid': showErrorEmail}" width="16" />
                    </template>
                </base-input>
            </div>

            <input type="hidden" value="true" name="usernameSubmitted"/>

            <div class="form-group">
                <a @click="continueStepOne" id="forgotPasswordButtonId" class="dh-btn-aqua submit-button">Continue</a>
            </div>
        </template>

        <template v-if="resetPassStep === 2 && securityQuestion">
            <!-- Step 2: Answer Security Question -->
            <div class="form-group with-icon" :class="{'is-invalid': showErrorSecurityQuestionAnswer}">
                <label for="answerId">{{securityQuestion}}?</label>
            </div>
            <div class="form-group with-icon" :class="{'is-invalid': showErrorSecurityQuestionAnswer}">
                <base-input
                    type="text"
                    uid="answerId"
                    name="answer"
                    v-model="securityQuestionAnswer"
                    v-fs-class="'forgot-pw-input-answer'"
                    :validationErrors="validationSecurityQuestionAnswer"
                    :placeholder="'Your answer'"
                    v-test-prop="'forgot-password-answer'"
                    v-jest-class="'forgot-password-answer'"
                >
                    <template #prefix>
                        <i class="form-control-icon" :class="{'is-invalid': showErrorSecurityQuestionAnswer}" width="16" >A</i>
                    </template>
                </base-input>
            </div>

            <div class="form-group">
                <a @click="continueStepTwo" id="questionButtonId" class="dh-btn-aqua submit-button">Continue</a>
            </div>
        </template>

        <template v-if="resetPassStep === 3">
            <!-- Step 3: Enter new password -->
            <div class="form-group with-icon" :class="{'is-invalid': showErrorNewPassword}">
                <div class="form-group-password-popover" v-if="showPasswordPopover">
                    <div class="form-group-password-popover-inner">
                        <div class="form-group-password-popover-title">Password must</div>
                        <div class="form-group-password-popover-item"><i :class="[checkForCharacterLength ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least 8 characters</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForCharacters ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least one letter</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForNumbers ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Contain at least one number</span></div>
                        <div class="form-group-password-popover-item"><i :class="[checkForRepeatingCharacters ? 'dh-icon-tick' : 'dh-icon-x-sm']"></i><span>Cannot contain three repeating characters</span></div>
                        <div class="form-group-password-popover-note">
                            <div class="form-group-password-popover-item">You cannot reuse your previous password</div>
                        </div>
                    </div>
                </div>

                <base-input
                    :type="showNewPasswordType"
                    uid="newPasswordId"
                    name="newPassword"
                    v-model="newPassword"
                    ref="newPasswordInput"
                    v-fs-class="'forgot-pw-new'"
                    :class="{'is-invalid': showErrorNewPassword}"
                    :validationErrors="validationErrorNewPassword"
                    :placeholder="'New password'"
                    v-test-prop="'forgot-password-new'"
                    v-jest-class="'forgot-password-new'"
                    @focus="showPasswordPopover = true; showErrorNewPasswordMatch = false"
                    @blur="showPasswordPopover = false"
                >
                    <template #prefix>
                        <icon-lock class="form-control-icon" :class="{'is-invalid': showErrorNewPassword}" width="16" />
                    </template>

                    <template #suffix>
                        <a class="form-group-show-pass" @click="showNewPassword = !showNewPassword" v-if="!showErrorNewPassword && newPassword.length > 0">{{showNewPassword ? 'Hide' : 'Show'}}</a>
                    </template>
                </base-input>

            </div>

            <div class="form-group with-icon" :class="{error: showErrorNewPasswordRepeat}">
                <base-input
                    :type="showNewPasswordRepeatType"
                    uid="confirmPasswordId"
                    name="confirmPassword"
                    v-model="newPasswordRepeat"
                    ref="newPasswordInput"
                    v-fs-class="'forgot-pw-new-repeat'"
                    :validationErrors="validationErrorNewPasswordRepeat"
                    :placeholder="'Repeat password'"
                    v-test-prop="'forgot-password-new-repeat'"
                    v-jest-class="'forgot-password-new-repeat'"
                >
                    <template #prefix>
                        <icon-lock class="form-control-icon" :class="{'is-invalid': showNewPasswordRepeat}" width="16" />
                    </template>

                    <template #suffix>
                        <a class="form-group-show-pass" @click="showNewPasswordRepeat = !showNewPasswordRepeat" v-if="!showErrorNewPasswordRepeat && newPasswordRepeat.length > 0">{{showNewPasswordRepeat ? 'Hide' : 'Show'}}</a>
                    </template>
                </base-input>

                <span class="form-group-error" v-if="showErrorNewPasswordMatch">Password does not match</span>

            </div>

            <input type="hidden" value="true" name="changePasswordSubmitted">

            <div class="form-group">
                <a @click="continueStepThree" id="changePasswordButtonId" class="dh-btn-aqua submit-button">Change password and log in</a>
            </div>
        </template>


        <div class="login-form-footer">
            <a :href="urls.loginUrl" class="login-form-footer-link"><i class="dh-icon-caret-left"></i> Back to login</a>
        </div>
    </form>
</template>

<script>
    import BaseInput from "../../../components/inputs/BaseInput";
    import IconUser from "../../../components/icons/flat/IconUser";
    import IconEmailEnvelope from "../../../components/icons/flat/IconEmailEnvelope";
    import IconLock from "../../../components/icons/flat/IconLock";

    export default {
        components: {BaseInput, IconUser, IconEmailEnvelope, IconLock},
        props: {
            urls: {
                type: Object
            },
            params: {
                type: [String, Object]
            }
        },
        data() {
            return {
                username: '',
                email: '',
                securityQuestionAnswer: '',
                newPassword: '',
                newPasswordRepeat: '',

                showErrorUsername: false,
                showErrorEmail: false,
                showErrorLogin: false,
                showErrorSecurityQuestionAnswer: false,
                showErrorNewPassword: false,
                showErrorNewPasswordRepeat: false,
                showErrorNewPasswordMatch: false,
                showPasswordPopover: false,
                showForgotSteps: false,

                showNewPassword: false,
                showNewPasswordType: 'password',
                showNewPasswordRepeat: false,
                showNewPasswordRepeatType: 'password',
                validationErrorEmail: [],
                validationErrorUsername: [],
                validationSecurityQuestionAnswer: [],
                validationErrorNewPassword: [],
                validationErrorNewPasswordRepeat: [],
            }
        },
        mounted() {
            if(this.params.loginErrors) {
                this.showErrorLogin = true
            }
        },
        computed: {
            resetPassStep() {
                if(this.params.form && this.params.form === 'forgot') return 1;
                if(this.params.form && this.params.form === 'question') return 2;
                if(this.params.form && this.params.form === 'resetPassword') return 3;
                return 1
            },
            postUrl() {
                if(this.params.form && this.params.form === 'resetPassword') return this.urls.resetPasswordAction;
                return this.urls.questionAction
            },
            securityQuestion() {
                if(this.params.securityQuestion) return this.params.securityQuestion
                return ''
            },
            getErrorMessage() {
                return this.params.loginErrors ? JSON.parse(this.params.loginErrors)[0] : ''
            },
            getFormName() {
                if(this.params.passwordChangeRequired) {
                    this.showForgotSteps = false;
                    return 'Update your password'
                } else {
                    this.showForgotSteps = true;
                    return 'Forgot password'
                }
            },

            // New Password Validation
            checkForCharacterLength() {
                return this.newPassword.length >= 8;
            },
            checkForCharacters() {
                let regex = RegExp('[a-z]', 'i');
                return regex.test(this.newPassword);
            },
            checkForNumbers() {
                let regex = RegExp('[0-9]', 'i');
                return regex.test(this.newPassword);
            },
            checkForRepeatingCharacters() {
                let regex = RegExp('(.)\\1{2}', 'i');
                return !regex.test(this.newPassword);
            },
            isNewPasswordValid() {
                return this.checkForCharacters && this.checkForNumbers && this.checkForRepeatingCharacters && this.checkForCharacterLength
            }
        },
        methods: {
            continueStepOne() {
                if (this.username === '') {
                    this.showErrorUsername = true;
                    this.validationErrorUsername = ['Required'];
                }
                if (this.email === '') {
                    this.showErrorEmail = true;
                    this.validationErrorEmail = ['Required'];
                }

                if (this.username === '' || this.email === '') return false;

                this.$refs.form.submit();
            },
            continueStepTwo() {
                if (this.securityQuestionAnswer === '') {
                    this.showErrorSecurityQuestionAnswer = true;
                    this.validationSecurityQuestionAnswer = ['Required'];
                    return false;
                }

                this.$refs.form.submit();
            },
            continueStepThree() {
                if (!this.isNewPasswordValid) {
                    if(this.$refs.newPasswordInput) {
                        this.$refs.newPasswordInput.setFocus();
                    }
                    return false;
                }
                if (this.newPassword !== this.newPasswordRepeat) {
                    this.showErrorNewPasswordMatch = true;
                    return false;
                }

                this.$refs.form.submit();
            }
        },
        watch: {
            username(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorUsername = false;
                    this.validationErrorUsername = [];
                }
            },
            email(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorEmail = false;
                    this.validationErrorEmail = [];
                }
            },
            securityQuestionAnswer(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorSecurityQuestionAnswer = false;
                    this.validationSecurityQuestionAnswer = [];
                }
            },
            newPassword() {
                this.showErrorNewPasswordMatch = false;
            },
            newPasswordRepeat(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorNewPasswordMatch = false;
                    this.validationErrorNewPasswordRepeat = [];
                }
            },
            showNewPassword (val) {
                this.showNewPasswordType = val ? 'text' : 'password';
            },
            showNewPasswordRepeat(val) {
                this.showNewPasswordRepeatType = val ? 'text' : 'password';
            }
        }
    }
</script>