<template>
    <input-mask
        class="form-control"
        :mask="'aaa{ }00{, }0000'"
        :lazy="true"
        :placeholder="placeholder ? placeholder : defaultPlaceholder"
        :value="modelValue"
        @input="handleDatePickerInput"
        @complete="handleDatePickerChange"
        @click="$emit('focus', true)"
        :tabindex="tabindex"
        :disabled="disabled"
        :readonly="readonly"
        v-test-prop="`input-date`"
    />
</template>

<script>
import inputMask from '../../../shared/form-elements-v2/date-picker-input/DatePickerInputMask';

const INPUT_DATE_FORMAT = 'MMM DD, YYYY'
const PROP_DATE_FORMAT = 'DD/MM/YYYY'

export default {
    name: 'input-date',
    inheritAttrs: false,
    components: {inputMask},
    props: {
        value: String,
        type: String,
        placeholder: String,
        tabindex: String,
        dateFormat: {
            type: String,
            default: PROP_DATE_FORMAT
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        modelValue: null,
	    defaultPlaceholder: INPUT_DATE_FORMAT,
        langSettings: {
            formatLocale: {
                firstDayOfWeek: 1
            }
        }
    }),
	mounted() {
		this.resetModelValue()
	},
    methods: {
		resetModelValue() {
			this.modelValue = this._formatDate(this.value, this.dateFormat, INPUT_DATE_FORMAT)
		},
	    handleDatePickerInput(e) {
			if (!e) this.$emit('input', null)
	    },
        handleDatePickerChange(e) {
			const dateFormatted = this._formatDate(e, INPUT_DATE_FORMAT, this.dateFormat)
            if (dateFormatted) this.$emit('input', dateFormatted)
	        else console.error(`Failed to parse date: ${e}`)
        },
	    _formatDate(date, inputFormat, outputFormat) {
			if (!date) return null
		    return this.$moment.utc(date, inputFormat).format(outputFormat)
	    }
    },
	watch: {
		value() { this.resetModelValue() }
	}
}
</script>