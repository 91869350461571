<template>
    <div class="login-form-eula">
        <div class="login-form-eula-header">
            <div class="login-form-eula-header-title">End User License Agreement</div>
            <a @click="$emit('close-popover')" class="login-form-eula-header-close dh-icon-x-sm"></a>
        </div>

        <div class="login-form-eula-body">
            <end-user-license-agreement></end-user-license-agreement>
        </div>
    </div>
</template>

<script>
    import EndUserLicenseAgreement from './_EndUserLicenseAgreement.vue';

    export default {
        components: {EndUserLicenseAgreement}
    }
</script>