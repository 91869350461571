var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "login-form",
      attrs: { action: _vm.postUrl, method: "post" }
    },
    [
      _c("div", { staticClass: "login-form-logo" }),
      _vm._v(" "),
      _vm.showErrorLogin
        ? _c("div", {
            staticClass: "login-form-failed-msg",
            domProps: { innerHTML: _vm._s(_vm.getErrorMessage) }
          })
        : _c("div", { staticClass: "login-form-title" }, [
            _c("div", [_vm._v(_vm._s(_vm.getFormName))]),
            _vm._v(" "),
            _vm.showForgotSteps
              ? _c("ul", { staticClass: "login-form-title-list" }, [
                  _c("li", { class: { active: _vm.resetPassStep === 1 } }, [
                    _vm._v("1. Enter your username and email")
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.resetPassStep === 2 } }, [
                    _vm._v("2. Answer your security question")
                  ]),
                  _vm._v(" "),
                  _c("li", { class: { active: _vm.resetPassStep === 3 } }, [
                    _vm._v("3. Enter new password")
                  ])
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _vm.resetPassStep === 1
        ? [
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "is-invalid": _vm.showErrorUsername }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "forgot-pw-input-username",
                      expression: "'forgot-pw-input-username'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "forgot-password-username",
                      expression: "'forgot-password-username'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "app-header-search-box",
                      expression: "'app-header-search-box'"
                    }
                  ],
                  attrs: {
                    type: "text",
                    uid: "usernameId",
                    name: "username",
                    validationErrors: _vm.validationErrorUsername,
                    placeholder: "Username"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-user", {
                              staticClass: "form-control-icon",
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3504489208
                  ),
                  model: {
                    value: _vm.username,
                    callback: function($$v) {
                      _vm.username = $$v
                    },
                    expression: "username"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group ",
                class: { "is-invalid": _vm.showErrorEmail }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "forgot-pw-input-email",
                      expression: "'forgot-pw-input-email'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "forgot-password-email",
                      expression: "'forgot-password-email'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "app-header-search-box",
                      expression: "'app-header-search-box'"
                    }
                  ],
                  attrs: {
                    type: "text",
                    uid: "emailId",
                    name: "email",
                    validationErrors: _vm.validationErrorEmail,
                    placeholder: "Email address"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-email-envelope", {
                              staticClass: "form-control-icon",
                              class: { "is-invalid": _vm.showErrorEmail },
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3164624275
                  ),
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                value: "true",
                name: "usernameSubmitted"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  attrs: { id: "forgotPasswordButtonId" },
                  on: { click: _vm.continueStepOne }
                },
                [_vm._v("Continue")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.resetPassStep === 2 && _vm.securityQuestion
        ? [
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorSecurityQuestionAnswer }
              },
              [
                _c("label", { attrs: { for: "answerId" } }, [
                  _vm._v(_vm._s(_vm.securityQuestion) + "?")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorSecurityQuestionAnswer }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "forgot-pw-input-answer",
                      expression: "'forgot-pw-input-answer'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "forgot-password-answer",
                      expression: "'forgot-password-answer'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "forgot-password-answer",
                      expression: "'forgot-password-answer'"
                    }
                  ],
                  attrs: {
                    type: "text",
                    uid: "answerId",
                    name: "answer",
                    validationErrors: _vm.validationSecurityQuestionAnswer,
                    placeholder: "Your answer"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c(
                              "i",
                              {
                                staticClass: "form-control-icon",
                                class: {
                                  "is-invalid":
                                    _vm.showErrorSecurityQuestionAnswer
                                },
                                attrs: { width: "16" }
                              },
                              [_vm._v("A")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1340222424
                  ),
                  model: {
                    value: _vm.securityQuestionAnswer,
                    callback: function($$v) {
                      _vm.securityQuestionAnswer = $$v
                    },
                    expression: "securityQuestionAnswer"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  attrs: { id: "questionButtonId" },
                  on: { click: _vm.continueStepTwo }
                },
                [_vm._v("Continue")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.resetPassStep === 3
        ? [
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { "is-invalid": _vm.showErrorNewPassword }
              },
              [
                _vm.showPasswordPopover
                  ? _c("div", { staticClass: "form-group-password-popover" }, [
                      _c(
                        "div",
                        { staticClass: "form-group-password-popover-inner" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group-password-popover-title"
                            },
                            [_vm._v("Password must")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForCharacterLength
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least 8 characters")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForCharacters
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least one letter")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForNumbers
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v("Contain at least one number")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group-password-popover-item" },
                            [
                              _c("i", {
                                class: [
                                  _vm.checkForRepeatingCharacters
                                    ? "dh-icon-tick"
                                    : "dh-icon-x-sm"
                                ]
                              }),
                              _c("span", [
                                _vm._v(
                                  "Cannot contain three repeating characters"
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0)
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "forgot-pw-new",
                      expression: "'forgot-pw-new'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "forgot-password-new",
                      expression: "'forgot-password-new'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "forgot-password-new",
                      expression: "'forgot-password-new'"
                    }
                  ],
                  ref: "newPasswordInput",
                  class: { "is-invalid": _vm.showErrorNewPassword },
                  attrs: {
                    type: _vm.showNewPasswordType,
                    uid: "newPasswordId",
                    name: "newPassword",
                    validationErrors: _vm.validationErrorNewPassword,
                    placeholder: "New password"
                  },
                  on: {
                    focus: function($event) {
                      _vm.showPasswordPopover = true
                      _vm.showErrorNewPasswordMatch = false
                    },
                    blur: function($event) {
                      _vm.showPasswordPopover = false
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-lock", {
                              staticClass: "form-control-icon",
                              class: { "is-invalid": _vm.showErrorNewPassword },
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "suffix",
                        fn: function() {
                          return [
                            !_vm.showErrorNewPassword &&
                            _vm.newPassword.length > 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "form-group-show-pass",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewPassword = !_vm.showNewPassword
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showNewPassword ? "Hide" : "Show"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2715583835
                  ),
                  model: {
                    value: _vm.newPassword,
                    callback: function($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group with-icon",
                class: { error: _vm.showErrorNewPasswordRepeat }
              },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "fs-class",
                      rawName: "v-fs-class",
                      value: "forgot-pw-new-repeat",
                      expression: "'forgot-pw-new-repeat'"
                    },
                    {
                      name: "test-prop",
                      rawName: "v-test-prop",
                      value: "forgot-password-new-repeat",
                      expression: "'forgot-password-new-repeat'"
                    },
                    {
                      name: "jest-class",
                      rawName: "v-jest-class",
                      value: "forgot-password-new-repeat",
                      expression: "'forgot-password-new-repeat'"
                    }
                  ],
                  ref: "newPasswordInput",
                  attrs: {
                    type: _vm.showNewPasswordRepeatType,
                    uid: "confirmPasswordId",
                    name: "confirmPassword",
                    validationErrors: _vm.validationErrorNewPasswordRepeat,
                    placeholder: "Repeat password"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prefix",
                        fn: function() {
                          return [
                            _c("icon-lock", {
                              staticClass: "form-control-icon",
                              class: {
                                "is-invalid": _vm.showNewPasswordRepeat
                              },
                              attrs: { width: "16" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "suffix",
                        fn: function() {
                          return [
                            !_vm.showErrorNewPasswordRepeat &&
                            _vm.newPasswordRepeat.length > 0
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "form-group-show-pass",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewPasswordRepeat = !_vm.showNewPasswordRepeat
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showNewPasswordRepeat
                                          ? "Hide"
                                          : "Show"
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1331338179
                  ),
                  model: {
                    value: _vm.newPasswordRepeat,
                    callback: function($$v) {
                      _vm.newPasswordRepeat = $$v
                    },
                    expression: "newPasswordRepeat"
                  }
                }),
                _vm._v(" "),
                _vm.showErrorNewPasswordMatch
                  ? _c("span", { staticClass: "form-group-error" }, [
                      _vm._v("Password does not match")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                value: "true",
                name: "changePasswordSubmitted"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "a",
                {
                  staticClass: "dh-btn-aqua submit-button",
                  attrs: { id: "changePasswordButtonId" },
                  on: { click: _vm.continueStepThree }
                },
                [_vm._v("Change password and log in")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "login-form-footer" }, [
        _c(
          "a",
          {
            staticClass: "login-form-footer-link",
            attrs: { href: _vm.urls.loginUrl }
          },
          [
            _c("i", { staticClass: "dh-icon-caret-left" }),
            _vm._v(" Back to login")
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group-password-popover-note" }, [
      _c("div", { staticClass: "form-group-password-popover-item" }, [
        _vm._v("You cannot reuse your previous password")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }