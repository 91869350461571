<template>
    <form class="login-form" :ref="'form'" :action="urls.loginUrl" @submit.prevent="onSubmit" method="post">
        <div class="login-form-logo"></div>

        <div class="login-form-failed-msg" v-if="getErrorMessage" v-html="getErrorMessage"></div>
        <div v-else class="login-form-title">Log in to C2P</div>

        <div class="form-group with-icon" :class="{'is-invalid': showErrorUsername, filled: username.length > 0}">
            <base-input
                type="text"
                uid="usernameId"
                name="username"
                v-model="username"
                v-fs-class="'login-username'"
                :validationErrors="validationErrorUsername"
                :placeholder="'Username'"
            >
                <template #prefix>
                    <icon-user class="form-control-icon" width="16" />
                </template>
            </base-input>
        </div>

        <div class="form-group with-icon" :class="{'is-invalid': showErrorPassword, filled: password.length > 0}">
            <base-input
                :type="showPasswordType"
                uid="passwordId"
                name="password"
                v-model="password"
                v-fs-class="'login-password'"
                :validationErrors="validationErrorPassword"
                :placeholder="'Password'"
            >
                <template #prefix>
                    <icon-lock class="form-control-icon" :class="{'is-invalid': showErrorPassword}" width="16" />
                </template>

                <template #suffix>
                    <a class="form-group-show-pass" @click="showPassword = !showPassword" v-if="!showErrorPassword && password.length > 0">{{showPassword ? 'Hide' : 'Show'}}</a>
                </template>
            </base-input>
        </div>

        <div class="form-group dh-checkbox">
            <input type="checkbox" id="keepMeLoggedInId" name="keepMeLoggedIn" v-model="keepMeLoggedIn">
            <label for="keepMeLoggedInId">Keep me logged in for longer.</label>
            <i class="dh-icon-circle-info" v-tooltip="'We will remember your login <br/>details. For security reasons we <br/>will periodically require login.'"></i>
        </div>

        <div class="form-group">
            <button type="submit" id="loginbuttonId" @click="onSubmit" class="dh-btn-aqua submit-button">Log in</button>
        </div>

        <div class="login-form-footer">
            <a :href="urls.forgotPasswordUrl" id="forgotPasswordUrlId" class="login-form-footer-link">Forgot your password?</a>
        </div>
    </form>
</template>

<script>
    import {SanitizeHtml} from "../../../helpers";
    import BaseInput from "../../../components/inputs/BaseInput.vue";
    import IconUser from "../../../components/icons/flat/IconUser.vue";
    import IconLock from "../../../components/icons/flat/IconLock.vue";

    export default {
        components: {BaseInput, IconLock, IconUser},
        props: ['urls', 'params'],
        data() {
            return {
                username: '',
                password: '',
                keepMeLoggedIn: false,
                showErrorUsername: false,
                showErrorPassword: false,
                showErrorLogin: false,
                showPassword: false,
                showPasswordType: 'password',
                validationErrorUsername: [],
                validationErrorPassword: []
            }
        },
        computed: {
            getErrorMessage() {
                if (this.params.loginErrors) {
                    let loginErr = ''
                    try {loginErr = JSON.parse(this.params.loginErrors)[0]}
                    catch (e) {}
                    if (loginErr) return SanitizeHtml(loginErr, ['a', 'br', 'b'], ['href'])
                }
                return ''
            }
        },
        methods: {
            onSubmit() {
                if (this.username === '') {
                    this.showErrorUsername = true;
                    this.validationErrorUsername = ['Required'];
                }
                if (this.password === '') {
                    this.showErrorPassword = true;
                    this.validationErrorPassword = ['Required'];
                }

                if (this.username === '' || this.password === '') return false;

                this.$refs.form.submit();
            }
        },
        watch: {
            username(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorUsername = false;
                    this.validationErrorUsername = [];
                }
            },
            password(val) {
                if (val && val.trim().length > 0) {
                    this.showErrorPassword = false;
                    this.validationErrorPassword = [];
                }
            },
            showPassword (val) {
                this.showPasswordType = val ? 'text' : 'password';
            },
        }
    }
</script>