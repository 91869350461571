export default {
    props: {
        color: {
            type: String,
            default: 'black-40',
            description: 'Modifies the color of the icon'
        },
        iconClass: {
            type: String,
            description: 'Generated class which styles the icon from the "COLOR" modifier above (this class is added automatically)'
        },
        width: {
            type: String, // This is a string number
            default: '16',
            description: 'Sizes the icon (px)'
        },
    },
    computed: {
        customStyle() {
            return 'min-width:'+this.width
        }
    }
}