var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "ng-txt-bold" }, [
        _vm._v(
          'IMPORTANT NOTICE: COMPLIANCE & RISKS LIMITED (REGISTERED NUMBER) 356948 WHOSE\n        REGISTERED OFFICE IS AT UNIT 9, EASTGATE AVENUE, EASTGATE BUSINESS PARK, LITTLE ISLAND, CORK, IRELAND ("C&R")\n        PROVIDES ACCESS TO A WEB BASED KNOWLEDGE MANAGEMENT DATABASE WHICH INCLUDES COMPLIANCE AND REGULATORY DATA\n        CONTENT. BY: (I) CLICKING ON THE "ACCEPT" BUTTON; OR (II) USING THE SERVICES; AND/OR (III) PAYING\n        THE INVOICE (WHERE RELEVANT) YOU AGREE TO THE TERMS OF THIS AGREEMENT WHICH WILL BIND YOU AS THE CUSTOMER\n        AND THE AUTHORISED USER (AS DEFINED BELOW).\n    '
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v(
            "\n            DEFINITIONS &\n            INTERPRETATION\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ng-pl-60" }, [
        _c(
          "table",
          {
            staticStyle: { padding: "20px" },
            attrs: { border: "0", cellpadding: "0", cellspacing: "0" }
          },
          [
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Agreement:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                    means the terms and conditions in this subscription agreement and the Order Form and any other\n                    documents explicitly incorporated by reference by the written agreement of the parties.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Authorised Users:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                    means those employees, agents and independent contractors who use C2P on behalf of the Customer.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("C2P:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the web based\n                    product licensed by C&R to the Customer including the C&R IP\n                    and the C&R Content.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("C&R IP:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the\n                    intellectual property rights in the C&R Content, the Software and\n                    the Services and any updates or modifications thereto.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Confidential Information:  ")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                    means information that is proprietary or confidential to the disclosing party but only to the\n                    extent that a reasonable person would consider such information as confidential.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Customer Content:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the\n                    data input by the Customer or the Authorised Users into C2P to\n                    facilitate the Customer's use of C2P.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("C&R Content:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the\n                    information provided by C&R as part of C2P which includes\n                    regulatory updates, expert comments, news and analysis and details of\n                    regulations and other documents.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Effective Date:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  " means the\n                    date set out in the Order Form.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Fees:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the fees payable\n                    by the Customer to C&R, as set out in the Order Form.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Initial Subscription Term:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the period of one (1) year from the Effective Date unless\n                    otherwise agreed in the Order Form.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Order Form:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the\n                    document forming part of this Agreement which sets out the details\n                    for the Services including the Fees and the number of Authorised\n                    Users.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Renewal Period:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                    the period described in clause 12.a.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Services:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the services\n                    provided by C&R under this Agreement including the provision of\n                    access to and training and support on C2P.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Software:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "means the software\n                    licensed by C&R to Customer under this Agreement which forms part\n                    of C2P including, databases, data schemas and data models.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("Subscription Term:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "has the\n                    meaning given in clause 12.a.\n                "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                {
                  staticClass: "ng-txt-bold",
                  staticStyle: { "vertical-align": "top" }
                },
                [_c("b", [_vm._v("User Licenses:")])]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "has the\n                    meaning given in clause 12.a.\n                "
                )
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "2" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            USER LicenseS\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R hereby\n                grants to the Customer's designated Authorised Users a\n                non-exclusive, non-transferable license to use C2P during the\n                Subscription Term solely for the Customer's internal business\n                operations (the \"User Licenses\").\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer undertakes:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        that the number\n                        of Authorised Users shall not exceed the number of User Licenses\n                        set out in the Order Form;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        that each User\n                        License may only be used by one Authorised User;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        that each\n                        Authorised User shall keep a secure password for use of C2P and\n                        shall keep that password confidential; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        to maintain a\n                        list of current Authorised Users and provide such list to C&R\n                        upon request.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                shall not access, store, distribute or transmit any viruses or any\n                material when using C2P that is considered illegal or harmful or\n                facilitates illegal activity.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer may\n                do any of the following within the Customer's business:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        view, search,\n                        copy and print out C&R Content from C2P;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        revise and\n                        customise C&R Content from C2P; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        make available\n                        to customers, suppliers and partners copies of the C&R Content\n                        from C2P provided this is done on a reasonable, non-systematic\n                        basis that is not commercially prejudicial to C&R.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer shall not except to the extent expressly permitted under this Agreement:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        attempt to\n                        modify, duplicate, create derivative works from, or distribute all\n                        or any portion of C2P (as applicable);\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        attempt to\n                        reverse compile, disassemble, reverse engineer or otherwise reduce\n                        to human-perceivable form all or any part of the Software;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        access all or\n                        any part of C2P in order to build a product or service which\n                        competes with C2P;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        use C2P to\n                        provide services to third parties;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        license, sell,\n                        rent, lease, transfer, assign, distribute, display, disclose, or\n                        otherwise commercially exploit, or otherwise make C2P available to\n                        any third party except the Authorised Users.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Authorised Users may be granted different rights of access to C2P. The relevant access rights\n                will be set out in the Order Form.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "3" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v(
            "\n            ADDITIONAL USER\n            LicenseS\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { START: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Subject to clause\n                3.b, the Customer may, upon written notice to C&R, purchase\n                additional User Licenses in excess of the number set out in the\n                Order Form or upgrade the User Licenses to provide additional\n                functionality.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                If C&R\n                approves the Customer's request, the Customer shall, within\n                thirty (30) days of the date of C&R's invoice, pay to C&R\n                the relevant Fees as set out in the invoice. If the additional\n                User Licenses or upgraded User Licenses are purchased by the\n                Customer part way through the Subscription Term, such Fees shall be\n                pro-rated for the remainder of the Subscription Term.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "4" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            SERVICES\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R shall\n                provide the Services to the Customer on and subject to the terms of\n                this Agreement during the Subscription Term.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R shall use\n                commercially reasonable endeavours to make C2P available 24 hours a\n                day, seven days a week, except for planned maintenance (carried out\n                during such times which are notified to the Customer) and\n                unscheduled maintenance.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R will\n                provide the Customer with support services and training on the use\n                of C2P as set out in the Order Form. Any additional Services may\n                be agreed between the parties and set out in the Order Form.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R shall\n                maintain an off-site back-up and disaster recovery facility. In\n                the event of a critical disaster the C2P data shall be restored\n                from the back up data as soon as reasonably possible.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                shall own all rights, title and interest in and to all of the\n                Customer Content and shall have sole responsibility for the\n                legality, reliability, integrity, accuracy and quality of the\n                Customer Content.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticStyle: {
          "margin-left": "1.18in",
          "text-indent": "-0.68in",
          "margin-bottom": "0",
          "font-variant": "normal",
          "font-weight": "normal",
          "line-height": "100%"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "5" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            THIRD PARTY\n            PROVIDERS\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                acknowledges that as part of using C2P, the Customer may be able to\n                access the website content of, correspond with, and purchase\n                products and services from, third parties via third-party websites\n                (e.g. to correspond with a C&R data partner or purchase a\n                standard). The Customer accesses this information at its own risk.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R makes no\n                representation or commitment and shall have no liability or\n                obligation whatsoever in relation to the content or use of, or\n                correspondence with, any such third-party website, or any\n                transactions completed, and any contract entered into by the\n                Customer, with any such third party. Any contract entered into and\n                any transaction completed via any third-party website is between\n                the Customer and the relevant third party, and not C&R.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                In some\n                situations C&R will provide content on C2P which has been\n                translated from its original language into another language. C&R\n                shall not be responsible for any inaccuracies or faults in the\n                translated version of any document. The original version shall\n                always prevail over the translated version in the event of any\n                conflicts.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "6" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            C&R'S\n            OBLIGATIONS\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R\n                undertakes to provide the Services with reasonable skill and care.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                Notwithstanding\n                the foregoing:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R does not\n                        warrant that the Customer's use of C2P will be uninterrupted or\n                        error-free; nor that C2P, the Services, the C&R Content,\n                        and/or the information obtained by the Customer through C2P will\n                        meet the Customer's requirements;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        The C&R\n                        Content is general and educational in nature and is not intended\n                        to constitute a definitive or complete statement of the law on any\n                        subject;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        Nothing in C2P\n                        nor any receipt or use of the Services, shall be construed or\n                        relied on as advertising or soliciting to provide any legal\n                        services, creating any solicitor-client relationship or providing\n                        any legal representation, advice or opinion; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R is not\n                        responsible for any delays, delivery failures, or any other loss\n                        or damage resulting from the transfer of data over communications\n                        networks and facilities, including the internet.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R reserves\n                the right to modify C2P at any time. C&R will make available\n                to the Customer all improvements from time to time made available\n                by it to other customers.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                All warranties,\n                representations, conditions and all other terms of any kind\n                whatsoever implied by statute or common law are, to the fullest\n                extent permitted by applicable law, excluded from this Agreement.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "7" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            CUSTOMER'S\n            OBLIGATIONS\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                warrants and represents that it has all necessary rights and\n                authority to enter into this Agreement or if\n                Customer is entering into this Agreement on behalf of a company,\n                organization, educational institution, or agency, that Customer has\n                the right and authority to legally bind such entity or organization\n                to the terms and obligations of this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                The Customer\n                warrants that it shall:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        comply with all\n                        applicable laws and regulations with respect to its activities\n                        under this Agreement;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        ensure that the\n                        Authorised Users use C2P in accordance with the terms and\n                        conditions of this Agreement and shall be responsible and liable\n                        for any Authorised User's breach of this Agreement; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        be solely\n                        responsible for procuring and maintaining its systems, network\n                        connections and telecommunications links to access C2P.\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticStyle: {
          "margin-left": "1.18in",
          "text-indent": "-0.68in",
          "margin-bottom": "0",
          "font-variant": "normal",
          "font-weight": "normal",
          "line-height": "100%"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "8" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            CHARGES AND\n            PAYMENT\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                In consideration\n                for receipt of the Services, the Customer shall pay the Fees to\n                C&R.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Fees are\n                payable annually in advance and C&R shall send the relevant\n                invoice to the Customer thirty (30) days prior to each anniversary\n                of the Effective Date. The Customer shall, on the Effective Date\n                or thirty (30) days prior to the relevant Renewal Period, provide\n                to C&R valid, up-to-date and complete bank account information\n                or purchase order information acceptable to C&R and any other\n                relevant valid, up-to-date and complete contact and billing\n                details.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                If the Customer\n                provides its bank account details to C&R, the Customer hereby\n                authorises C&R to bill such account:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        on the Effective\n                        Date for the Fees payable in respect of the Initial Subscription\n                        Term; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        subject to\n                        clause 12.a, on each anniversary of the Effective Date for the\n                        Fees payable in respect of the next Renewal Period;\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                If the Customer\n                provides its purchase order information to C&R, C&R shall\n                invoice the Customer:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        on the Effective\n                        Date for the Fees payable in respect of the Initial Subscription\n                        Term; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        subject to\n                        clause 12.a, at least thirty (30) days prior to each anniversary\n                        of the Effective Date for the Fees payable in respect of the next\n                        Renewal Period.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                agrees to pay each invoice within the period agreed in the Order\n                Form.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                If C&R has\n                not received payment within the period specified in the Order Form:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R may,\n                        without liability to the Customer, disable the Customer's,\n                        account and access to all or part of C2P. C&R shall be under\n                        no obligation to provide any or all of the Services while the\n                        invoice(s) concerned remain unpaid; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R may\n                        charge interest at a monthly rate of 2% of the invoice amount.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                All Fees stated\n                or referred to in this Agreement:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        shall be payable\n                        in the currency specified in the Order Form;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        are\n                        non-cancellable and non-refundable; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        are exclusive of\n                        value added tax or other applicable sales tax, which shall be\n                        added to C&R's invoice(s) at the appropriate rate.\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticStyle: {
          "margin-left": "1.18in",
          "text-indent": "-0.68in",
          "margin-bottom": "0",
          "font-variant": "normal",
          "font-weight": "normal",
          "line-height": "100%"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "9" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v(
            "\n            PROPRIETARY\n            RIGHTS AND CONFIDENTIALITY\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                acknowledges and agrees that C&R and/or its licensors own all\n                intellectual property rights in the C&R IP. Except as expressly\n                stated herein, this Agreement does not grant the Customer any\n                rights to, or in, patents, copyrights, database rights, trade\n                secrets, trade names, trademarks (whether registered or\n                unregistered), or any other rights or Licenses in respect of the\n                C&R IP.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Each party may be\n                given access to the Confidential Information of the other party in\n                order to perform its obligations under this Agreement. A party's\n                Confidential Information shall not be deemed to include information\n                that:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        is or becomes\n                        publicly known other than through any act or omission of the\n                        receiving party;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        was in the other\n                        party's lawful possession before the disclosure;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        is lawfully\n                        disclosed to the receiving party by a third party without\n                        restriction on disclosure; or\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        is independently\n                        developed by the receiving party, which independent development\n                        can be shown by written evidence.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Each party shall\n                hold the other's Confidential Information in confidence and, unless\n                required by law, shall not make the other's Confidential\n                Information available to any third party, or use the other's\n                Confidential Information for any purpose other than the\n                implementation of this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Each party shall\n                take all reasonable steps to ensure that the other's Confidential\n                Information to which it has access is not disclosed or distributed\n                by its employees or agents in violation of the terms of this\n                Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R\n                acknowledges that the Customer Content is the Confidential\n                Information of the Customer.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                acknowledges that C&R IP is the Confidential Information of\n                C&R.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                agrees that by submitting any individual's personal information\n                to C&R, Customer agrees and confirms its authority for C&R's\n                collection, use and disclosure of such personal information in\n                order to provide the Services under this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n\n                If C&R\n                processes any personal data on the Customer's behalf when\n                performing its obligations under this Agreement, the parties record\n                their intention that the Customer shall be the data controller and\n                C&R shall be a data processor and in any such case:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the Customer\n                        acknowledges and agrees that the personal data may be transferred\n                        or stored outside the European Economic Area or the country where\n                        the Customer and the Authorised Users are located in order to\n                        carry out the Services and C&R's other obligations under\n                        this Agreement;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the Customer\n                        shall ensure that the Customer is entitled to transfer the\n                        relevant personal data to C&R so that the C&R may lawfully\n                        use, process and transfer the personal data in accordance with\n                        this Agreement on the Customer's behalf; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R shall\n                        process the personal data only in accordance with the terms of\n                        this Agreement and any lawful instructions reasonably given by the\n                        Customer from time to time.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                This clause shall\n                survive termination of this Agreement, however arising.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "10" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            INDEMNITY\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Subject to clause\n                11, C&R shall defend the Customer against any third party\n                claims that the C&R IP infringes any copyright, trade mark or\n                database right and shall indemnify the Customer for any amounts\n                awarded against the Customer in judgment or settlement of such\n                claims, provided that:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R is given\n                        prompt notice of any such claim;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the Customer\n                        provides reasonable co-operation to C&R in the defence and\n                        settlement of such claim, at C&R's expense; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R is given\n                        sole authority to defend or settle the claim.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                In the defence or\n                settlement of any claim, C&R may procure the right for the\n                Customer to continue using the C&R IP, replace or modify the\n                C&R IP so that it becomes non-infringing or, if such remedies\n                are not reasonably available, terminate this Agreement on two (2)\n                days' notice to the Customer without any additional liability to\n                the Customer as a result of such early termination.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                In no event shall\n                C&R, its employees, agents and sub-contractors be liable to the\n                Customer to the extent that the alleged infringement is based on:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        a modification\n                        of the C&R IP by anyone other than C&R; or\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the Customer's\n                        use of C2P in a manner contrary to the instructions given to the\n                        Customer by C&R or in breach of the terms of this Agreement;\n                        or\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the Customer's\n                        use of C2P after notice of the alleged or actual infringement from\n                        C&R or any appropriate authority.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The foregoing\n                states the Customer's sole and exclusive rights and remedies, and\n                C&R's (including C&R's employees', agents' and\n                sub-contractors') entire obligations and liability, for\n                infringement of any intellectual property right.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                shall defend and indemnify C&R against claims, actions,\n                proceedings, losses, damages, expenses and costs (including without\n                limitation court costs and reasonable legal fees) arising out of or\n                in connection with the Customer's use of C2P other than in\n                accordance with this Agreement.\n            "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "11" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            LIMITATION OF\n            LIABILITY\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                This clause 11\n                sets out the entire financial liability of C&R (including any\n                liability for the acts or omissions of its employees, contributing\n                experts, agents and sub-contractors) to the Customer.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Nothing in this\n                Agreement excludes the liability of C&R, for death or personal\n                injury caused by C&R's negligence or for fraud or fraudulent\n                misrepresentation.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Subject to clause\n                11.b:\n\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R shall\n                        not be liable whether in tort (including for negligence or breach\n                        of statutory duty), contract, misrepresentation, restitution or\n                        otherwise for any loss of profits, loss of business, depletion of\n                        goodwill and/or similar losses or loss or corruption of data or\n                        information, or pure economic loss, or for any special, indirect\n                        or consequential loss, costs, damages, charges or expenses however\n                        arising under this Agreement; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        C&R's total\n                        aggregate liability in contract, tort (including negligence or\n                        breach of statutory duty), misrepresentation, restitution or\n                        otherwise, arising in connection with the performance or\n                        contemplated performance of this Agreement shall be limited to the\n                        total Fees paid by the Customer during the twelve (12) months\n                        immediately preceding the date on which the claim arose.\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticStyle: {
          "margin-left": "1.18in",
          "text-indent": "-0.68in",
          "margin-bottom": "0",
          "font-variant": "normal",
          "font-weight": "normal",
          "line-height": "100%"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "12" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            TERM AND\n            TERMINATION\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              '\n                This Agreement\n                shall, subject to early termination in accordance with this\n                Agreement, commence on the Effective Date and shall continue for\n                the Initial Subscription Term as applicable and, thereafter, this\n                Agreement shall be automatically renewed for successive periods of\n                12 months (each a "'
            ),
            _c("b", [_vm._v("Renewal Period")]),
            _vm._v(
              '"),\n                unless either party notifies the other party of termination, in\n                writing, at least sixty (60) days before the end of the Initial\n                Subscription Term or any Renewal Period (together "'
            ),
            _c("b", [_vm._v("the\n                Subscription Term")]),
            _vm._v('").\n            ')
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R may grant\n                access to C2P on a trial basis under this Agreement. Such access\n                may be terminated by C&R at any time.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Without prejudice\n                to any other rights or remedies to which the parties may be\n                entitled, either party may terminate this Agreement without\n                liability to the other if:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the other party\n                        is in material or persistent breach of any of its obligations\n                        under this Agreement and either that breach is incapable of\n                        remedy, or the other party has failed to remedy that breach within\n                        twenty (20) days after receiving written notice requiring it to\n                        remedy that breach; or\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the other party\n                        is unable to pay its debts or becomes insolvent or an order is\n                        made or a resolution passed for the administration, winding-up or\n                        dissolution (otherwise than for the purposes of a solvent\n                        amalgamation or reconstruction) or an administrative or other\n                        receiver, manager, liquidator, administrator, trustee or similar\n                        officer is appointed over all or any substantial part of the\n                        assets of the other or the other enters into or proposes any\n                        composition or arrangement with its creditors generally or\n                        anything analogous to the foregoing occurs in any applicable\n                        jurisdiction.\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                On termination of\n                this Agreement for any reason:\n                "
            ),
            _c("ol", { attrs: { type: "i" } }, [
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the User\n                        Licenses granted under this Agreement shall immediately terminate;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        each party shall\n                        make no further use of any Confidential Information (and all\n                        copies of them) belonging to the other party;\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        Customer shall\n                        have thirty (30) days to export the Customer Content using the C2P\n                        export to Excel function. Following this period C&R may\n                        destroy the Customer Content in its possession; and\n                    "
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
                _vm._v(
                  "\n                        the accrued\n                        rights of the parties as at termination, or the continuation after\n                        termination of any provision expressly stated to survive or\n                        implicitly surviving termination, shall not be affected or\n                        prejudiced.\n                    "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", {
        staticStyle: {
          "margin-left": "1.18in",
          "text-indent": "-0.68in",
          "margin-bottom": "0",
          "font-variant": "normal",
          "font-weight": "normal",
          "line-height": "100%"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "13" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            FORCE MAJEURE\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ng-pl-60" }, [
        _vm._v(
          "\n        C&R shall have no liability to the Customer under this Agreement if it is prevented\n        from or delayed in performing its obligations under this Agreement,\n        or from carrying on its business, by acts, events, omissions or\n        accidents beyond its reasonable control, including, without\n        limitation, strikes, lock-outs or other industrial disputes (whether\n        involving the workforce of C&R or any other party), failure of a\n        utility service or transport or telecommunications network, act of\n        God, war, riot, civil commotion, malicious damage, compliance with\n        any law or governmental order, rule, regulation or direction,\n        accident, breakdown of plant or machinery, fire, flood, storm or\n        default of suppliers or sub-contractors, provided that the Customer\n        is notified of such an event and its expected duration.\n    "
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("ol", { attrs: { start: "14" } }, [
        _c("li", { staticClass: "ng-txt-bold" }, [
          _vm._v("\n            GENERAL\n            PROVISIONS\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("ol", { attrs: { start: "1", type: "a" } }, [
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                If any provision\n                (or part of a provision) of this Agreement is found by any court or\n                administrative body of competent jurisdiction to be invalid,\n                unenforceable or illegal, the other provisions shall remain in\n                force.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                This Agreement,\n                and any documents referred to in it, constitute the whole agreement\n                between the parties and supersede any previous arrangement,\n                understanding or agreement between them relating to the subject\n                matter they cover.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Each of the\n                parties acknowledges and agrees that in entering into this\n                Agreement it does not rely on any undertaking, promise, assurance,\n                statement, representation, warranty or understanding (whether in\n                writing or not) of any person (whether party to this Agreement or\n                not) relating to the subject matter of this Agreement, other than\n                as expressly set out in this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The Customer\n                shall not, without the prior written consent of C&R, assign,\n                transfer, charge, sub-contract or deal in any other manner with all\n                or any of its rights or obligations under this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                C&R may at\n                any time assign, transfer, charge, sub-contract or deal in any\n                other manner with all or any of its rights or obligations under\n                this Agreement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                Any\n                notice to be given under this Agreement will be in writing and\n                addressed to the party at the address stated in the Order Form.\n                Notices will be deemed given and effective (i) if personally\n                delivered, upon delivery, (ii) if sent by an overnight service with\n                tracking capabilities, upon receipt; (iii) if sent by fax or\n                electronic mail, at such time as the party which sent the notice\n                receives confirmation of receipt by the applicable method of\n                transmittal, or (iv) if sent by certified or registered mail,\n                within five days of deposit in the mail.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The terms and\n                conditions in the Order Form shall prevail over the terms and\n                conditions in this subscription agreement to the extent of any\n                conflict. Terms\n                contained in any purchase order or acknowledgement will be of no\n                effect, even if such acknowledgement provides that C&R's\n                acceptance of the purchase order is conditioned on Customer's\n                agreement to the proposed terms contained in such purchase order or\n                acknowledgement.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                This Agreement\n                and any disputes or claims arising out of or in connection with it\n                or its subject matter or formation (including non-contractual\n                disputes or claims) are governed by, and construed in accordance\n                with: (i) the laws of the Republic of Ireland where the Customer's\n                registered or head office is in any country outside of the United\n                States of America; and (ii) the laws of the State of California\n                where the Customer's registered or head office is in the United\n                States of America.\n            "
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "ng-txt-normal ng-pb-10" }, [
            _vm._v(
              "\n                The parties\n                irrevocably agree that in relation to any dispute or claim that\n                arises out of or in connection with this Agreement or its subject\n                matter or formation (including non-contractual disputes or claims):\n                (i) the courts of the Republic of Ireland shall have jurisdiction\n                where the Customer's registered or head office in any country\n                outside of the United States of America; and (ii) the state courts\n                of the State of California shall have jurisdiction where the\n                Customer's registered or head office is in the United States of\n                America.\n            "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }