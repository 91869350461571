var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "login-page-holder" },
    [
      _c("img", {
        staticClass: "st-patrick-day__login",
        staticStyle: { display: "none" },
        attrs: {
          src: "/c2png/assets/themes/st-patrick/login-title.svg",
          alt: "St Patricks Day with Shamrock"
        }
      }),
      _vm._v(" "),
      _vm.checkCookieEnabled()
        ? [
            _c(_vm.activeForm, {
              tag: "component",
              attrs: { urls: _vm.urls, params: _vm.params }
            })
          ]
        : [_vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-form" }, [
      _c("div", { staticClass: "login-form-logo" }),
      _vm._v(" "),
      _c("div", { staticClass: "login-form-failed-msg" }, [
        _vm._v(
          "\n                It appears that you do not have cookies enabled. C2P requires cookies to be enabled.\n            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }