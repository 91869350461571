export const INPUT_TYPES = {
    text: 'text',
    textarea: 'textarea',
    date: 'date',
    number: 'number',
    richText: 'richText',
    password: 'password',
}
export const INPUT_TYPE_COMPONENTS = {
    [INPUT_TYPES.text]: 'InputText',
    [INPUT_TYPES.textarea]: 'InputTextarea',
    [INPUT_TYPES.date]: 'InputDate',
    [INPUT_TYPES.number]: 'InputNumber',
    [INPUT_TYPES.richText]: 'InputRichText',
    [INPUT_TYPES.password]: 'InputPassword',
}
export const INPUT_SIZES = {
    small: 'sm',
    default: 'default',
    medium: 'md',
    large: 'lg'
}
export const INPUT_DROPDOWN_DIR = {
    down: 'down',
    up: 'up'
}

export const CHECKBOX_COLORS = ['default', 'aqua', 'lavender', 'magenta', 'orange']