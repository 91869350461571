<template>
    <svg class="svg-icon" :class="'icon--c-'+color" viewBox="0 0 16 16" :width="width" height="100%" preserverAspectRatio="xMinYMid">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8C5.79086 8 4 6.20914 4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4C12 6.20914 10.2091 8 8 8ZM8 6C9.10457 6 10 5.10457 10 4C10 2.89543 9.10457 2 8 2C6.89543 2 6 2.89543 6 4C6 5.10457 6.89543 6 8 6ZM0 13.8251V15.986H16V13.8251C16 12.5904 15.6093 11.3555 14.8284 10.4135C14.0474 9.47108 13.0238 9 12.0001 9H3.9999C2.97633 9 1.95263 9.47129 1.17175 10.4135C0.390508 11.3555 0 12.5904 0 13.8251Z" />
    </svg>
</template>

<script>
import iconDefault from "./mixins/_iconDefault.js";

export default {
    mixins: [iconDefault],
    name: 'icon-user'
}
</script>