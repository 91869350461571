import IMask from 'imask/dist/imask.min';

export const DefaultEmptyValue = '___ __, ____'

export default {
    render: function render(createElement) {
        var _this = this;

        var props = {
            domProps: {
                value: this.maskRef ? this.maskRef.value : this.value,
            },
            on: Object.assign({}, this.$listeners)
        }; // if there is no mask use default input event

        if (this.disabled) props.domProps['disabled'] = 'disabled'
        if (this.readonly) props.domProps['readonly'] = 'readonly'

        if (!this.$props.mask) {
            props.on.input = function (event) {
                return _this.$emit('input', event.target.value);
            };
        } else {
            delete props.on.input;
        }

        return createElement('input', props);
    },
    mounted: function mounted() {
        if (!this.$props.mask) return;

        this._initMask();
    },
    destroyed: function destroyed() {
        this._destroyMask();
    },
    computed: {
        maskOptions: function maskOptions() {
            return this._extractOptionsFromProps(this.$props);
        }
    },
    watch: {
        '$props': {
            handler: function handler(props) {
                var maskOptions = this.maskOptions;

                if (maskOptions.mask) {
                    if (this.maskRef) {
                        this.maskRef.updateOptions(maskOptions);

                        if ('value' in props && (props.value !== this._maskValue() || // handle cases like Number('') === 0,
                            // for details see https://github.com/uNmAnNeR/imaskjs/issues/134
                            typeof props.value !== 'string' && this.maskRef.value === '' && !this.maskRef.el.isActive)) {
                            this._updateValue();
                        }
                    } else {
                        this._initMask(maskOptions);

                        if (props.value !== this._maskValue()) this._onAccept();
                    }
                } else {
                    this._destroyMask();

                    if ('value' in props) this.$el.value = props.value;
                }
            },
            deep: true
        }
    },
    methods: {
        _extractOptionsFromProps: function _extractOptionsFromProps(props) {
            props = Object.assign({}, props); // keep only defined props

            Object.keys(props).filter(function (prop) {
                return props[prop] === undefined;
            }).forEach(function (undefinedProp) {
                delete props[undefinedProp];
            });
            delete props.value;
            delete props.unmask;
            return props;
        },
        _maskValue: function _maskValue() {
            if (this.unmask === 'typed') return this.maskRef.typedValue;
            if (this.unmask) return this.maskRef.unmaskedValue;
            return this.maskRef.value;
        },
        _updateValue: function _updateValue() {
            var value = this.value == null ? '' : this.value;
            if (this.unmask === 'typed') this.maskRef.typedValue = value; else if (this.unmask) this.maskRef.unmaskedValue = value; else this.maskRef.value = value;
        },
        _onAccept: function _onAccept() {
            var val = this._maskValue();

            this.$emit('input', val);
            this.$emit('accept', val);
        },
        _onComplete: function _onComplete() {
            this.$emit('complete', this._maskValue());
        },
        _initMask: function _initMask() {
            var maskOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.maskOptions;
            this.maskRef = IMask(this.$el, maskOptions).on('accept', this._onAccept.bind(this)).on('complete', this._onComplete.bind(this));

            this._updateValue();
        },
        _destroyMask: function _destroyMask() {
            if (this.maskRef) {
                this.maskRef.destroy();
                delete this.maskRef;
            }
        }
    },
    props: {
        // common
        mask: {},
        value: {},
        unmask: {
            validator: function validator(value) {
                return value === 'typed' || typeof value === 'boolean';
            }
        },
        prepare: Function,
        validate: Function,
        commit: Function,
        overwrite: {
            type: Boolean,
            required: false,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // pattern
        placeholderChar: String,
        lazy: {
            type: Boolean,
            required: false,
            default: undefined
        },
        definitions: Object,
        blocks: Object,
        // date
        pattern: String,
        format: Function,
        parse: Function,
        autofix: {
            type: Boolean,
            required: false,
            default: undefined
        },
        // number
        radix: String,
        thousandsSeparator: String,
        mapToRadix: Array,
        scale: Number,
        signed: {
            type: Boolean,
            required: false,
            default: undefined
        },
        normalizeZeros: {
            type: Boolean,
            required: false,
            default: undefined
        },
        padFractionalZeros: {
            type: Boolean,
            required: false,
            default: undefined
        },
        min: [Number, Date],
        max: [Number, Date],
        // dynamic
        dispatch: Function
    }
};