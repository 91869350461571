import Vue from 'vue';
import VTooltip from 'v-tooltip';
import FsAnalyticsDirective from "../../shared/directives/FsAnalytics.js";
import JestClass from "../../shared/directives/UnitTest";
import TestPropDirective from "../../shared/directives/TestProp";
import LoginPage from './LoginPage.vue';
import "../../assets/styles/main.scss";

Vue.use(VTooltip, {defaultClass: 'white', defaultDelay: 200, popover: {defaultDelay: 200}});
Vue.directive('on-clickaway', { });

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
    el: '#loginPage',
    components: {
        LoginPage,
        FsAnalyticsDirective,
        JestClass,
        TestPropDirective
    }
}).$mount();