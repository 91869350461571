var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("quill-editor", {
        directives: [
          {
            name: "test-prop",
            rawName: "v-test-prop",
            value: _vm.uid + "-input",
            expression: "`${uid}-input`"
          }
        ],
        attrs: {
          value: _vm.initialValue,
          options: _vm.getEditorOptions,
          disabled: _vm.disabled,
          uid: _vm.uid
        },
        on: { change: _vm.quillUpdate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }