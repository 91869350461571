<template>
    <div class="quill-editor" ref="quillEditorContainer" :class="{'is--focused': isFocused}">
        <div :id="'quillToolbar' + uid" ref="quillToolbar" v-if="showCustomToolbar">
            <div class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
            </div>

            <div class="ql-formats">
                <select class="ql-header">
                    <option value="1">Header 1</option>
                    <option value="2">Header 2</option>
                    <option value="3">Header 3</option>
                    <option selected>Normal</option>
                </select>

                <select class="ql-color"></select>
            </div>

            <div class="ql-formats">
                <select class="ql-align"></select>
                <button class="ql-indent" value="-1"></button>
                <button class="ql-indent" value="+1"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-blockquote"></button>
                <button class="ql-link"></button>
            </div>

            <div class="ql-formats">
                <div id="quill-custom-actions"></div>

                <button class="ql-tableUI">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M452,50H60C26.916,50,0,76.916,0,110v292c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V110
			C512,76.916,485.084,50,452,50z M156,422H60c-11.028,0-20-8.972-20-20v-62h116V422z M156,300H40v-88h116V300z M156,172H40v-62
			c0-11.028,8.972-20,20-20h96V172z M317,422H196v-82h121V422z M317,300H196v-88h121V300z M317,172H196V90h121V172z M472,402
			c0,11.028-8.972,20-20,20h-95v-82h115V402z M472,300H357v-88h115V300z M472,172H357V90h95c11.028,0,20,8.972,20,20V172z"/></g></g></svg>
                </button>
            </div>

            <div class="ql-formats">
                <button class="ql-undo undo"><i class="dh-icon-undo" /></button>
                <button class="ql-redo redo"><i class="dh-icon-undo" /></button>
            </div>
        </div>
        <div ref="quillEditor" v-html="value"></div>
    </div>
</template>

<script>
// Import Quill.js
import Quill from 'quill'

// Register custom formats
const Parchment = Quill.import('parchment')
import IndentFormat from "./formats/quill.indentFormat";

Quill.register(IndentFormat, true)
Quill.register(new Parchment.StyleAttributor('width', 'width', {}));

// Register custom modules
import * as QuillTableUI from 'quill-table-ui'

Quill.register({'modules/tableUI': QuillTableUI.default}, true)

// Other imports
import {QuillDefaultOptions} from "./QuillConstants";
import cloneDeep from 'lodash/cloneDeep'

export default {
    data: () => ({
        quill: null,
        _options: {},
        content: '',
        isFocused: false,
    }),
    props: {
        value: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: () => (QuillDefaultOptions)
        },
        toolbarOffset: {
            type: Number,
            default: 0
        },
        uid: {
            type: String,
            default: '',
            validator: val => {
                let valPieces = val.split(' ')
                return valPieces.length === 1
            }
        }
    },
    mounted() {
        this.adjustOptions();
        this.initialize()
        document.addEventListener('scroll', this.quillStickyToolbar)
    },
    beforeDestroy() {
        this.quill = null
        delete this.quill
        document.removeEventListener('scroll', this.quillStickyToolbar)
    },
    computed: {
        showCustomToolbar() {
            return this.options && this.options.modules && typeof this.options.modules.toolbar === 'string'
        }
    },
    methods: {
        // Init Quill instance
        initialize() {
            if (this.$el) {
                // Attach custom handlers to toolbar
                const toolbarOptions = cloneDeep(this.options)
                if (this.showCustomToolbar) {
                    toolbarOptions.modules.toolbar = {
                        container: this.options.modules.toolbar,
                        handlers: this.getToolbarCustomHandlers()
                    }
                }

                // Create instance
                this.quill = new Quill(this.$refs.quillEditor, toolbarOptions)

                // Disable editor
                this.quill.enable(false)

                // Enable editor
                if (!this.disabled) {
                    this.quill.enable(true)
                }

                // Update model if text changes
                this.quill.on('text-change', (delta, oldDelta, source) => {
                    let html = this.$refs.quillEditor.children[0].innerHTML
                    const quill = this.quill
                    const text = this.quill.getText()
                    if (html === '<p><br></p>') html = ''
                    this._content = html
                    this.$emit('change', {html, text, quill})
                })

                this.quill.on('selection-change', range => {
                    if (!range) {
                        this.isFocused = false
                        this.$emit('blur')
                    } else {
                        this.isFocused = true
                        this.$emit('focus')
                    }
                });
            }
        },
        getToolbarCustomHandlers() {
            return {
                'tableUI': function () {
                    this.quill.getModule('table').insertTable(3, 3);
                },
                'undo': function () {
                    this.quill.history.undo()
                },
                'redo': function () {
                    this.quill.history.redo()
                }
            }
        },
        adjustOptions() { // Needed when multiple Quill instances are on the same page
            if (this.showCustomToolbar) this.options.modules.toolbar = "#quillToolbar" + this.uid
        },
        quillStickyToolbar() {
            const quillEditorContainer = this.$refs.quillEditorContainer.getBoundingClientRect()
            const quillToolbar = this.$refs.quillToolbar
            const toolbarOffset = (this.toolbarOffset - 4)

            if (quillEditorContainer.top < toolbarOffset) {
                quillToolbar.style.position = 'sticky'
                quillToolbar.style.top = toolbarOffset + 'px'
            } else {
                quillToolbar.style.position = 'static'
                quillToolbar.style.top = 'auto'
            }
        }
    }
}
</script>