import Vue from "vue";
import { TestConstants } from "../Constants";

export default Vue.directive('testProp', function (el, bindings) {
    let props = Object.keys(bindings.modifiers)
    if (props.length === 0)
        el.setAttribute(TestConstants.CYPRESS_TEST_REF, bindings.value)
    else
        el.setAttribute(
            `${TestConstants.CYPRESS_TEST_REF}-${props.join('-')}`,
            bindings.value
        )
});