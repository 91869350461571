<template>
    <input
        class="form-control"
        type="number"
        :class="[`form-control-${size}`, {'form-control-plaintext': readonly}]"
        :id="uid"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        :tabindex="tabindex"
        @input="$emit('input', $event.target.value)"
        @keypress="$emit('keypress', $event)"
        @focus="$emit('focus', true)"
        @blur="$emit('focus', false)"
        onwheel="return false;"
    >
</template>

<script>
import {INPUT_SIZES} from "../InputConstants";

export default {
    props: {
        uid: {
            type: String,
            required: true
        },
        size: {
            type: String,
            validator: val => Object.values(INPUT_SIZES).includes(val),
            default: INPUT_SIZES.default
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Type something'
        },
        value: Number,
        tabindex: String
    },
    methods: {
        setFocus() {
            this.$el.focus()
        }
    }
}
</script>