var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "form-control",
    class: [
      "form-control-" + _vm.size,
      { "form-control-plaintext": _vm.readonly }
    ],
    attrs: {
      type: "number",
      id: _vm.uid,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      tabindex: _vm.tabindex,
      onwheel: "return false;"
    },
    domProps: { value: _vm.value },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      },
      keypress: function($event) {
        return _vm.$emit("keypress", $event)
      },
      focus: function($event) {
        return _vm.$emit("focus", true)
      },
      blur: function($event) {
        return _vm.$emit("focus", false)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }